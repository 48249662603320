import { Container, Text, Divider } from "@chakra-ui/layout";
import React from "react";

const ProfileScreen = ({ title, content }) => {
	return (
		<Container width="100%" maxW="100%" height="100%" color="#111111">
			<Text fontSize="4xl" width="100%" fontStyle="italic" >
				{title}
			</Text>
            <Divider />
			{content}
		</Container>
	);
};

export default ProfileScreen;
