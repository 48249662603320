import { Stack, Flex, Button, Text, VStack } from "@chakra-ui/react";
import NavBar from "../Navbar";
import Swan from "../../assets/Swan_09.jpg";
import { Link as ReachLink } from "@reach/router";
import useAuthentication from "../../redux/hooks/useAuthentication";
const Header = ({ hiddenHero }) => {
	const user = useAuthentication();

	return (
		<>
			<NavBar />

			{!hiddenHero && (
				<Flex
					w="full"
					minH={{ base: "90vh", md: "auto", "2xl": "90vh" }}
					py={{ base: 4, md: 24, lg: 24 }}
					px={{ base: 4, lg: 16, xl: 32 }}
					backgroundImage={`url(${Swan})`}
					bgSize={{ base: "60%", md: "50%" }}
					bgPosition={{ base: "100% 100%", lg: "110% 40%" }}
					bgRepeat="no-repeat"
					bgColor="#fff"
				>
					<VStack
						w="6xl"
						justify={{ base: "flex-start", lg: "center" }}
						alignItems="flex-start"
						px={{ base: 2, lg: 8 }}
					>
						<Stack
							maxW="6xl"
							align={{ base: "flex-start", lg: "center" }}
							spacing={6}
						>
							<TextHeader
								label={
									<>
										Ha llegado
										<Text color="#d75408" fontStyle="italic">
											{" "}
											tu momento.
										</Text>
									</>
								}
							/>
							<TextHeader
								label={
									<>
										<Text>Trabaja conmigo y te ayudaré</Text>a obtener tu mejor
										versión.
									</>
								}
							/>
							<TextHeader
								label={
									<>
										Tienes un reto?
										<Text color="#d75408" fontStyle="italic">
											Acompáñame y logra tus objetivos.
										</Text>
									</>
								}
							/>

							<Stack direction={{ base: "column", md: "row" }}>
								{!user ? (
									<Button
										bg="white"
										color="#fff"
										size={"lg"}
										minW={{ base: "inherit", md: "150px" }}
										maxW={{ base: "150px", md: "inherit" }}
										fontSize={{ base: ".85rem", md: "1.35rem" }}
										fontStyle="italic"
										as={ReachLink}
										to="/acceder/registro"
										bgGradient="linear(to-r, blue.500, blue.300)"
										_hover={{
											bgGradient: "linear(to-r, blue.500, blue.300)",
											boxShadow: "xl",
										}}
										h={{ base: "35px", sm: "48px" }}
									>
										Prueba 15 días gratis
									</Button>
								) : null}
							</Stack>
						</Stack>
					</VStack>
				</Flex>
			)}
		</>
	);
};

const TextHeader = ({ label }) => {
	return (
		<Text
			w="100%"
			color="#000"
			fontWeight="black"
			lineHeight={1.2}
			fontSize={{
				base: "3xl",
				md: "4xl",
				lg: "5xl",
				"2xl": "6xl",
			}}
		>
			{label}
		</Text>
	);
};

export default Header;
