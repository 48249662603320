import { Image } from '@chakra-ui/image'
import React from 'react'

const Logo = () => {
    return (
        <Image src="https://api.entrenaconswan.com/wp-content/uploads/2020/07/cropped-logo-web-entrenaconswan-1536x359.png" />
    )
}

export default Logo
