import {
    Box,
    Container,
    Stack,
    Text,
    Link,
    VisuallyHidden,
    chakra,
    Image,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import useAuthentication from '../../redux/hooks/useAuthentication';
import { Link as ReachLink } from '@reach/router';
import { useEffect, useState } from 'react';
import Library from '../../library';
import GobiernoDeNavarra from '../../assets/gov_navarra.jpeg';
const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

const SocialButton = ({ children, label, href }) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const Footer = () => {
    const user = useAuthentication();
    const [views, setViews] = useState([]);

    const initialize = async () => {
        const views = await Library.getMenu();
        setViews(views);
    };

    useEffect(
        () => initialize(),
        // eslint-disable-next-line
        []
    );

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
        >
            <Container as={Stack} maxW={'6xl'} py={10}>
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    spacing={{ base: 2, lg: 8 }}
                >
                    <Stack align={'flex-start'}>
                        <ListHeader>Nosotros</ListHeader>
                        <Link as={ReachLink} to="/planes">
                            Planes
                        </Link>
                        <Link as={ReachLink} to="/contacto">
                            Contacto
                        </Link>
                        {!user ? (
                            <Link as={ReachLink} to="/acceder">
                                Acceder
                            </Link>
                        ) : null}
                    </Stack>

                    <Stack align={'flex-start'}>
                        <ListHeader>Legal</ListHeader>
                        {views.map(view => (
                            <Link
                                key={view.label}
                                as={ReachLink}
                                to={view.path}
                            >
                                {view.label}
                            </Link>
                        ))}
                    </Stack>

                    <Stack
                        direction={{
                            base: 'column',
                            lg: 'row',
                        }}
                        alignItems={{
                            base: 'center',
                            lg: 'center',
                        }}
                        flex={1}
                        spacing={{ base: 2, lg: 8 }}
                    >
                        <Image
                            src={GobiernoDeNavarra}
                            objectFit="contain"
                            w="320px"
                        />
                        <VStack fontSize={12}>
                            <Text>
                                Esta empresa/organismo ha recibido una ayuda
                                cofinanciada por el Gobierno de Navarra y el
                                Fondo Europeo de Desarrollo Regional (40%) a
                                través del Programa Operativo FEDER 2021-2027 de
                                Navarra
                            </Text>
                            <Text>
                                Enpresa honek laguntza bat jaso du, erdi bana
                                (%40) finantzatu dutena Nafarroako Gobernuak eta
                                Eskualde Garapeneko Europako Funtsak, Nafarroako
                                EGEF 2021-2027 Programa Eragilearen bidez
                            </Text>
                        </VStack>
                    </Stack>
                </Stack>
            </Container>

            <Box
                borderTopWidth={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ md: 'space-between' }}
                    align={{ md: 'center' }}
                >
                    <Text textAlign={{ base: 'center', md: 'left' }}>
                        © 2021 Entrena con Swan. Todos los Derechos Reservados.
                    </Text>
                    <Stack
                        direction={'row'}
                        spacing={6}
                        justify={{ base: 'center', md: 'flex-start' }}
                    >
                        <SocialButton
                            label={'Facebook'}
                            href={
                                'https://www.facebook.com/swancoaching-108825271340866'
                            }
                        >
                            <FaFacebook />
                        </SocialButton>
                        <SocialButton
                            label={'Instagram'}
                            href={'https://www.instagram.com/swan.coaching/'}
                        >
                            <FaInstagram />
                        </SocialButton>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default Footer;
