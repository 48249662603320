import Library from "..";

/**
 * Class that handles user authentication and register
 */
class Authentication {
	/**
	 * @description Method that takes as parameter an username and a password, and returns the auth data.
	 * @param {string} username
	 * @param {string} password
	 * @returns { authToken: string, refreshToken: string, id: string, name: string, email: string }
	 */
	static async doLogin(username, password) {
		const {
			login: {
				authToken,
				refreshToken,
				user: {
					id,
					name,
					email,
					capabilities,
					registeredDate,
					memberships,
					firstName,
					lastName,
                    databaseId
				},
				customer: { billing },
			},
		} = await Library.doLogin(username, password);
		return {
			authToken,
			refreshToken,
			id,
			name,
			email,
			registeredDate,
			capabilities,
			memberships,
			firstName,
			lastName,
            billing,
            databaseId
		};
	}

	static async changePassword(newPassword, newPasswordRepeat) {
		return await Library.changePassword(newPassword, newPasswordRepeat);
	}
}

export default Authentication;
