import { navigate } from "@reach/router";
import Library from "../../library";
import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Action that refresh the auth token of the user
 */
class RefreshUserAction extends Action {
  constructor() {
    super("refreshUser");
  }

  async action(state, payload) {
    const { user } = state;

    const { authToken, refreshToken, navigateTo } = payload || {};

    // If there's a session stored, refresh the user data
    if (user || authToken || refreshToken) {
      if (authToken) {
        localStorage.setItem("authToken", authToken);
      }

      if (refreshToken) {
        localStorage.setItem("refreshToken", refreshToken);
      }

      try {
        const {
          viewer: {
            email,
            id,
            name,
            firstName,
            lastName,
            username,
            memberships,
          },
        } = await Library.getUser();

        const {
          customer: { shipping, billing },
        } = await Library.getCustomer(id);

        if (navigateTo) {
          navigate(navigateTo);
        }

        return {
          ...state,
          user: {
            ...user,
            email,
            id,
            name,
            firstName,
            lastName,
            username,
            memberships,
            shipping,
            billing,
          },
        };
      } catch (error) {
        console.error(error);
        return { ...state, user: null };
      }
    }

    return { ...state };
  }
}

Registry.registerAction(RefreshUserAction);
