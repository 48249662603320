import { Box, Stack, Heading } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import Default from '../components/Layout/Default';
import Library from '../library';
const View = ({ path }) => {
    const [data, setData] = useState({});
    useEffect(() => {
        const getTodo = async () => {
            const req = await Library.getView(path);
            setData(req);
        };
        getTodo();
         // eslint-disable-next-line
    }, []);
    return (
        <Default hiddenHero>
            <Stack spacing={4} py={{ base: 4, sm: 6, md: 8 }} px={{ base: 4, sm: 6, md: 32 }}>
                <Heading color={'gray.800'} lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} fontWeight="black">
                    {data.title}
                </Heading>
            </Stack>
            <Stack spacing={4} px={{ base: 4, sm: 6, md: 32 }} py={{ base: 4, sm: 6, md: 8 }}>
                <Box as="div" color={'gray.800'} lineHeight={1.1} dangerouslySetInnerHTML={{ __html: data.content}} />
            </Stack>
        </Default>
    );
};

export default View;
