import React from "react";
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Center,
	Box,
	Button,
	Flex,
	Text,
	chakra,
	Heading,
	useColorModeValue,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import AutomaticPlan from "./AutomaticPlan";
import UnloggedSubscription from './UnloggedSubscriptions'
import Default from "../../components/Layout/Default";
import Subscriptions from "./Subscriptions";
import { useSelector } from "react-redux";
import { navigate } from "@reach/router";

const rows = [
	{
		animal: (
			<Text
				fontSize="2xl"
				textAlign="center"
				fontWeight="black"
				fontStyle="italic"
				color="green.500"
			>
				León
			</Text>
		),
		fuerza: 5,
		resistencia: 1,
		potencia: 1,
		equilibrio: 2,
		actividades: "ENTRENAMIENTO DE FUERZA, CORE, ESTIRAMIENTOS",
		quien: "MUJERES, PERSONAS CON SOBREPESO, DEPORTES DE FUERZA",
	},
	{
		animal: (
			<Text
				fontSize="2xl"
				textAlign="center"
				fontWeight="black"
				fontStyle="italic"
				color="blue.500"
			>
				Lobo
			</Text>
		),
		fuerza: 1,
		resistencia: 5,
		potencia: 1,
		equilibrio: 2,
		actividades: "HIIT,CICLO INDOOR, CORE, ESTIRAMIENTOS",
		quien: "BAJAR VOLUMEN, CORREDORES, SEDENTARIOS",
	},
	{
		animal: (
			<Text
				fontSize="2xl"
				textAlign="center"
				fontWeight="black"
				fontStyle="italic"
				color="red.500"
			>
				Gacela
			</Text>
		),
		fuerza: 3,
		resistencia: 2,
		potencia: 5,
		equilibrio: 2,
		actividades: "FUERZA, HIIT, CORE, FUERZA EXPLOSVA, ESTIRAMIENTOS",
		quien: "DEPORTISTAS",
	},
	{
		animal: (
			<Text
				fontSize="2xl"
				textAlign="center"
				fontWeight="black"
				fontStyle="italic"
				color="yellow.500"
			>
				Oso
			</Text>
		),
		fuerza: 2,
		resistencia: 3,
		potencia: 1,
		equilibrio: 3,
		actividades: "TODAS LAS ACTIVIDADES",
		quien: "PERSONA QUE BUSCA FORMA FÍSICA GENERAL Y DISFRUTE",
	},
	{
		animal: (
			<Text
				fontSize="2xl"
				textAlign="center"
				fontWeight="black"
				fontStyle="italic"
				color="green.500"
			>
				Flamenco
			</Text>
		),
		fuerza: 2,
		resistencia: 1,
		potencia: 1,
		equilibrio: 5,
		actividades: "CORE, ESTIRAMIENTOS, PILATES",
		quien: "TODOS",
	},
];

const Plans = () => {
	const user = useSelector((state) => state.user);

	return (
		<Default hiddenHero>
			<Flex pb={32} px={{ base: 8, lg: 32 }} justify="center" flexWrap="wrap">
				{user && !user.memberships.length ? (
					<Subscriptions />
				) : user && (
					<Box display="flex" flexDir="column" alignItems="center">
						<Heading as="h1" fontSize="4xl" fontWeight={"black"} mt="4rem" mb="2rem">
							Contacta con soporte para cambiar de plan.
						</Heading>
						<Button
							onClick={() => navigate("/contacto")}
							bgGradient="linear(to-r, blue.500, blue.300)"
							_hover={{
								bgGradient: "linear(to-r, blue.500, blue.300)",
								boxShadow: "xl",
							}}
							margin="0 auto"
							w="lg"
							color="#fff"
							variant="outline"
							mb="4rem"
						>
							Contáctanos 
						</Button>
					</Box>
				)}
				{!user && (
					<UnloggedSubscription />
				)}
				<AutomaticPlan />
				<Box w="100%" overflowX={{ base: "auto", lg: "hidden" }}>
					<Table
						variant="simple"
						w="100%"
						my={8}
						display={{ base: "none", lg: "inherit" }}
					>
						<Thead>
							<Tr>
								<Th></Th>
								<Th>Fuerza</Th>
								<Th>Resistencia</Th>
								<Th>Potencia</Th>
								<Th>Equilibrio</Th>
								<Th>Actividades</Th>
								<Th>Para Quien</Th>
							</Tr>
						</Thead>
						<Tbody>
							{rows.map((row) => (
								<Tr key={row.animal}>
									<Row row={row} />
								</Tr>
							))}
							<Tr></Tr>
						</Tbody>
						<Tfoot>
							<Tr>
								<Th></Th>
								<Th>Fuerza</Th>
								<Th>Resistencia</Th>
								<Th>Potencia</Th>
								<Th>Equilibrio</Th>
								<Th>Actividades</Th>
								<Th>Para Quien</Th>
							</Tr>
						</Tfoot>
					</Table>
					<Center w="100%" display={{ base: "none", lg: "inherit" }}>
						<AutomaticPlan />
					</Center>

					{rows.map((row) => (
						<ResponsiveCard row={row} />
					))}
				</Box>
			</Flex>
		</Default>
	);
};

export const ResponsiveCard = ({ row }) => {
	return (
		<Flex
			py={4}
			w="full"
			alignItems="center"
			justifyContent="center"
			display={{ base: "inherit", lg: "none" }}
		>
			<Box
				w="100%"
				bg={useColorModeValue("white", "gray.800")}
				shadow="lg"
				rounded="lg"
				overflow="hidden"
			>
				<Flex
					flexWrap="wrap"
					justifyContent="space-between"
					alignItems="center"
					px={4}
					py={3}
					bg="gray.900"
				>
					{row.animal}
					<chakra.h1 width="100%" fontSize="xl" fontWeight="bold" color="white">
						Para Quien
					</chakra.h1>
					<chakra.p width="100%" color="white">
						{row.quien}
					</chakra.p>
				</Flex>

				<Box py={4} px={6}>
					<chakra.h1
						mx={3}
						color={useColorModeValue("gray.700", "gray.400")}
						fontWeight="bold"
						fontSize="lg"
					>
						Actividades
					</chakra.h1>

					<chakra.p py={2} color={useColorModeValue("gray.700", "gray.400")}>
						{row.actividades}
					</chakra.p>

					<Flex
						alignItems="center"
						mt={4}
						color={useColorModeValue("gray.700", "gray.200")}
					>
						<Stars count={row.fuerza} />
						<chakra.h1 px={2} fontSize="sm" fontWeight="bold">
							Fuerza
						</chakra.h1>
					</Flex>

					<Flex
						alignItems="center"
						mt={4}
						color={useColorModeValue("gray.700", "gray.200")}
					>
						<Stars count={row.resistencia} />
						<chakra.h1 px={2} fontSize="sm" fontWeight="bold">
							Resistencia
						</chakra.h1>
					</Flex>

					<Flex
						alignItems="center"
						mt={4}
						color={useColorModeValue("gray.700", "gray.200")}
					>
						<Stars count={row.potencia} />
						<chakra.h1 px={2} fontSize="sm" fontWeight="bold">
							Potencia
						</chakra.h1>
					</Flex>
					<Flex
						alignItems="center"
						mt={4}
						color={useColorModeValue("gray.700", "gray.200")}
					>
						<Stars count={row.equilibrio} />
						<chakra.h1 px={2} fontSize="sm" fontWeight="bold">
							Equilibrio
						</chakra.h1>
					</Flex>
				</Box>
			</Box>
		</Flex>
	);
};
const Stars = ({ count }) => {
	return (
		<>
			{" "}
			{Array(5)
				.fill("")
				.map((_, i) => (
					<StarIcon key={i} color={i < count ? "yellow.500" : "gray.300"} />
				))}{" "}
		</>
	);
};

const Row = ({ row }) => {
	return (
		<>
			<Td>
				<Flex mt="2" direction="column" alignItems="center">
					<Text fontSize="1.50rem" fontStyle="italic" fontWeight="semibold">
						{row.animal}
					</Text>
				</Flex>
			</Td>
			<Td>
				<Box d="flex" mt="2" alignItems="center">
					<Stars count={row.fuerza} />
				</Box>
			</Td>
			<Td>
				<Box d="flex" mt="2" alignItems="center">
					<Stars count={row.resistencia} />
				</Box>
			</Td>
			<Td>
				<Box d="flex" mt="2" alignItems="center">
					<Stars count={row.potencia} />
				</Box>
			</Td>
			<Td>
				<Box d="flex" mt="2" alignItems="center">
					<Stars count={row.equilibrio} />
				</Box>
			</Td>
			<Td>{row.actividades}</Td>
			<Td>{row.quien}</Td>
		</>
	);
};

export default Plans;
