import React from 'react'
import { Stack, Text, Input, Select, Divider } from "@chakra-ui/react";
import handleChange from "../../../library/api/utils/handleChange";
import { states } from "./states";

const EditLocationInputs = ({ setFormData, formData }) => {
    return (
        <>
            <Stack
                display="flex"
                flexDir="column"
                padding={{ base: 0, md: 15 }}
                marginTop={{ base: "2rem", md: 0 }}
            >
                <Text
                    fontSize={{ base: "sm", md: "lg" }}
                    fontWeight="semibold"
                    mb="0.2rem"
                    color="#D75408"
                >
                    Dirección de la calle <b style={{ color: "red" }}>*</b>
                </Text>
                <Input
                    required
                    marginTop="auto"
                    onChange={handleChange((val) =>
                        setFormData({ ...formData, address1: val })
                    )}
                    placeholder="Nombre de la calle y número"
                    bg={"gray.100"}
                    border={0}
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                        fontSize: { base: "xs", md: "sm" },
                    }}
                    maxW="20rem"
                    value={formData.address1}
                />
                <Input
                    marginTop="auto"
                    onChange={handleChange((val) =>
                        setFormData({ ...formData, address2: val })
                    )}
                    placeholder="Piso, planta, letra (opcional)"
                    bg={"gray.100"}
                    border={0}
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                        fontSize: { base: "xs", md: "sm" },
                    }}
                    maxW="20rem"
                    value={formData.address2}
                />
            </Stack>
            <Divider />
            <Stack display="flex" flexDir="column" padding={{ base: 0, md: 15 }}>
                <Text
                    fontSize={{ base: "sm", md: "lg" }}
                    mb="0.2rem"
                    fontWeight="semibold"
                    color="#D75408"
                >
                    Nombre de empresa (opcional)
                </Text>
                <Input
                    marginTop="auto"
                    onChange={handleChange((val) =>
                        setFormData({ ...formData, company: val })
                    )}
                    placeholder="Añade el nombre de la empresa"
                    bg={"gray.100"}
                    border={0}
                    maxW="20rem"
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                        fontSize: { base: "xs", md: "sm" },
                    }}
                    value={formData.company}
                />
            </Stack>
            <Divider />
            <Stack position="relative" padding={{ base: 0, md: 15 }}>
                <div style={{ display: "flex" }}>
                    <Text
                        fontSize={{ base: "sm", md: "lg" }}
                        mb="0.2rem"
                        fontWeight="semibold"
                        color="#D75408"
                    >
                        Provincia <b style={{ color: "red" }}>*</b>
                    </Text>
                </div>

                <Select
                    onChange={handleChange((val) =>
                        setFormData({ ...formData, state: val })
                    )}
                    value={formData.state}
                    required
                    placeholder="Añade tu provincia"
                    bg={"gray.100"}
                    border={0}
                    maxW="20rem"
                    color={!!formData.state ? "black.500" : "gray.500"}
                >
                    {states.map((state) => (
                        <option key={state.value} value={state.value}>
                            {" "}
                            {state.name}
                        </option>
                    ))}
                </Select>
            </Stack>
            <Divider />
            <Stack position="relative" padding={{ base: 0, md: 15 }}>
                <div style={{ display: "flex" }}>
                    <Text
                        fontSize={{ base: "sm", md: "lg" }}
                        mb="0.2rem"
                        fontWeight="semibold"
                        color="#D75408"
                    >
                        Cuidad <b style={{ color: "red" }}>*</b>
                    </Text>
                </div>
                <Input
                    onChange={handleChange((val) =>
                        setFormData({ ...formData, city: val })
                    )}
                    required
                    value={formData.city}
                    placeholder="Localidad / Ciudad"
                    bg={"gray.100"}
                    border={0}
                    maxW="20rem"
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                    }}
                />
            </Stack>
            <Divider />
            <Stack display="flex" flexDir="column" padding={{ base: 0, md: 15 }}>
                <Text
                    fontSize={{ base: "sm", md: "lg" }}
                    mb="0.2rem"
                    fontWeight="semibold"
                    color="#D75408"
                >
                    Código Postal <b style={{ color: "red" }}>*</b>
                </Text>
                <Input
                    onChange={handleChange((val) =>
                        setFormData({ ...formData, postcode: val })
                    )}
                    value={formData.postcode}
                    placeholder="CP"
                    bg={"gray.100"}
                    required
                    border={0}
                    maxW="20rem"
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                    }}
                />
            </Stack>
            <Divider />
            <Stack />
            <Stack display="flex" flexDir="column" padding={{ base: 0, md: 15 }}>
                <Text
                    fontSize={{ base: "sm", md: "lg" }}
                    mb="0.2rem"
                    fontWeight="semibold"
                    color="#D75408"
                >
                    Teléfono (opcional)
                </Text>
                <Input
                    maxW="20rem"
                    type="tel"
                    marginTop="auto"
                    onChange={handleChange((val) =>
                        setFormData({ ...formData, phone: val })
                    )}
                    placeholder="Añade tu teléfono"
                    bg={"gray.100"}
                    border={0}
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                        fontSize: { base: "xs", md: "sm" },
                    }}
                    value={formData.phone}
                />
            </Stack>
        </>
    )
}

export default EditLocationInputs