import { gql } from "@apollo/client";
import _ from "lodash";

export const SEND_PASSWORD_RESET_LINK = (email) => gql`
  mutation sendPasswordResetEmail {
    sendPasswordResetEmail(input: { username: "${email}" }) {
      user {
        email
      }
    }
  
  }
`;

export const CHANGE_PASSWORD = (newPassword, newPasswordRepeat) => gql`
  mutation ChangePassword {
    __typename
    changePassword(input: { newPassword: "${newPassword}", newPasswordRepeat: "${newPasswordRepeat}" }) {
      success
      message
    }
  }
`;

export const LOGIN_USER = (username, password) => gql`
  mutation LoginUser {
    login(
      input: {
        clientMutationId: "${_.uniqueId("auth_")}"
        username: "${username}"
        password: "${password}"
      }
    ) {
      authToken
      refreshToken
      customer {
        billing {
            address1
            address2
            city
            company
            country
            email
            firstName
            lastName
            phone
            postcode
            state
        }

        shipping {
          address1
          address2
          city
          country
          postcode
          state
          phone
        }
      }
      user {
        name
        databaseId
        email
        id
        firstName
        lastName
        username
        registeredDate
        memberships {
          name
          status
        }
      }
    }
  }
`;

export const REGISTER_CUSTOMER = (username, password, email) => gql`
  mutation RegisterCustomer {
    registerCustomer(
      input: {
        clientMutationId: "${_.uniqueId("auth_")}"
        username: "${username}"
        password: "${password}"
        email: "${email}"
    }
    ) {
        customer {
            jwtRefreshToken
            jwtAuthToken
            billing {
              address1
              address2
              city
              company
              country
              email
              firstName
              lastName
              phone
              postcode
              state
            }
            id
            email
            firstName
            lastName
            username
          }
          viewer {
            memberships {
              name
              status
            }
            name
            databaseId
            id
            capabilities
            registeredDate
            firstName
            lastName
          }
          refreshToken
          authToken
    }
  }
`;

export const REFRESH_AUTH_TOKEN = (refreshToken) => gql`
  mutation RefreshAuthToken {
    refreshJwtAuthToken(
      input: {
        clientMutationId: "${_.uniqueId()}"
        jwtRefreshToken: "${refreshToken}"
      }
    ) {
      authToken
    }
  }
`;

export const GET_USER = () => gql`
	query GetUser {
		viewer {
			name
			email
			id
      databaseId
			firstName
			lastName
			username
			memberships {
				name
				status
			}
		}
	}
`;

export const GET_CUSTOMER = (customerId) => gql`
  query GetCustomer {
    customer(id: "${customerId}") {
      billing {
        address1
        address2
        city
        company
        country
        state
        postcode
        phone
      }

      shipping {
        address1
        address2
        city
        company
        country
        state
        postcode
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
	mutation ChangeCustomer($input: UpdateCustomerInput!) {
		updateCustomer(input: $input) {
			customer {
				id
			}
		}
	}
`;

export const CANCEL_MEMBERSHIP = gql`
	mutation CancelUserMembership {
		cancelUserMembership(input: { clientMutationId: "whatever" }) {
			success
			message
		}
	}
`;
