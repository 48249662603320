import React, { useState } from "react";
import { Box, Stack, Heading, Input, Button, useToast } from "@chakra-ui/react";
import useAction from "../../redux/api/hooks/useAction";
import Authentication from "../../library/api/Authentication";
import handleChange from "../../library/api/utils/handleChange";
import { navigate } from "@reach/router";
import SendPasswordResetLinkModal from "./SendPasswordResetLinkModal/SendPasswordResetLinkModal";
// import { useSelector } from "react-redux";

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordResetOpened, setIsPasswordResetOpened] = useState(false);
  const toast = useToast();

  const doLogin = useAction("login");

  const handleLogin = async () => {
    setLoading(true);
    try {
      const authData = await Authentication.doLogin(username, password);
      await doLogin(authData);

      navigate("/");
    } catch (error) {
      const type = {
        invalid_email:
          "El correo electrónico ingresado no existe. Intentalo de nuevo.",
        too_many_retries:
          "Demasiados intentos fallidos. Por favor, intente de nuevo más tarde.",
        incorrect_password: "La contraseña es incorrecta. Intentalo de nuevo.",
        "Internal server error":
          "Error de servidor, se recomienda limpiar cache del ordenador, si el problema persiste, contacte con soporte.",
        default: "Ha ocurrido un error al iniciar sesión. Intentalo de nuevo.",
      };
      const msg = error?.graphQLErrors[0]?.message || "default";
      toast({
        title: "Error",
        description: type[msg],
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error(error.graphQLErrors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SendPasswordResetLinkModal
        isOpened={isPasswordResetOpened}
        onClose={() => setIsPasswordResetOpened(false)}
      />
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          Accede a tu cuenta
        </Heading>
      </Stack>
      <Box as={"form"} mt={10}>
        <Stack spacing={4}>
          <Input
            onChange={handleChange((val) => setUsername(val.toLowerCase()))}
            placeholder="Correo Electrónico"
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
          />
          <Input
            onChange={handleChange((val) => setPassword(val))}
            placeholder="Contraseña"
            type="password"
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
          />
        </Stack>
        <Button
          onClick={handleLogin}
          disabled={
            !username || !password || username === "" || password === ""
          }
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400, orange.400)"
          color={"white"}
          isLoading={loading}
          loadingText="Cargando..."
          _hover={{
            bgGradient: "linear(to-r, red.400, orange.400)",
            boxShadow: "xl",
          }}
        >
          Acceder
        </Button>
        <Button
          onClick={() => setIsPasswordResetOpened(true)}
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400, orange.400)"
          color={"white"}
          _hover={{
            bgGradient: "linear(to-r, red.400, orange.400)",
            boxShadow: "xl",
          }}
        >
          ¿Olvidaste tu contraseña?
        </Button>
      </Box>
    </>
  );
};

export default SignIn;
