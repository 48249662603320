import * as React from "react";
// 1. import 'ChakraProvider' component
import { ChakraProvider } from "@chakra-ui/react";
import ReduxAPIProvider from "./redux/api/ReduxAPIProvider";
import Pages from "./pages";
const App = () => {
  // 2. Use at the root of your app
  return (
    <ChakraProvider>
      <ReduxAPIProvider>
        <Pages />
      </ReduxAPIProvider>
    </ChakraProvider>
  );
};

export default App;
