import { gql } from "@apollo/client";

export const SEND_CONTACT_FORM = () => gql`
  mutation CreateSubmission(
    $email: String!
    $message: String!
    $name: String!
    $phone: String!
  ) {
    createSubmission(
      input: { email: $email, message: $message, name: $name, phone: $phone }
    ) {
      data
      success
    }
  }
`;
