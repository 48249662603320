import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Saves the recently created order inside the local storage
 */
class SaveRecentlyCreatedOrderAction extends Action {
  constructor() {
    super("saveRecentlyCreatedOrder");
  }

  async action(state, { order, result, redirect }) {
    return { ...state, recentlyCreatedOrder: { order, result, redirect } };
  }
}

Registry.registerAction(SaveRecentlyCreatedOrderAction);
