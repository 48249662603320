import { Avatar, Box, chakra, Flex, Text, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import Paula from '../../assets/Paula.jpeg';
import Iker from '../../assets/Iker.jpeg';
import Nerea from '../../assets/Nerea.jpeg';
import Miguel from '../../assets/Miguel.jpeg';
import Laila from '../../assets/Laila.jpeg';
import Izaskun from '../../assets/Izaskun.jpg';
import styled from '@emotion/styled';

const testimonials = [
    {
        name: 'Nerea Bermejo',
        role: 'Atleta de 400 vallas. Mayor logro: 3 de España Absoluta en 400v (57.89)  ',
        content:
            'Considero que mi entrenador es una persona perseverante, con ideas y metas muy claras de lo que quiere conseguir. Aprendiendo constantemente para poder encontrar el mejor método y entrenamiento para cada persona.',
        avatar: Nerea,
    },
    {
        name: 'Miguel Barandalla',
        role: 'Jugador del Athletic Club',
        content:
            'Hola soy Miguel Barandalla jugador del Athletic Club y estoy entrenando con Swan. Para mí el poder entrenar con él me ha ayudado mucho a mejorar la velocidad al igual que me ha preparado de forma extraordinaria para el inicio de la pretemporada. Sin duda ha sido un verdadero profesional en todos los aspectos, muy recomendable para el que busque progresar físicamente.',
        avatar: Miguel,
    },
    {
        name: 'Iker Alfonso',
        role: 'Atleta de 400m vallas. Subcampeón de España Absoluto (50.96).',
        content: 'François es un gran técnico: paciente, detallista y comprometido. Sabe fijar objetivos y trabajar duro para conseguirlos.',
        avatar: Iker,
    },
    {
        name: 'Laila Lacuey',
        role: 'Atleta del Grupompleo Pamplona Atlético. Recordista Navarra absoluta de salto de longitud y medallista nacional en categorías sub23 y absoluta.',
        content: 'En cuestión de poco tiempo François me enseñó a ser más disciplinada, ahora, me acompaña en el trabajo diario en busca de grandes metas',
        avatar: Laila,
    },
    {
        name: 'Paula Suárez',
        role: 'Jugadora de baloncesto internacional sub 20 y Club Baloncesto Perfumerías Avenida de Salamanca.',
        content:
            'En estos años de entrenamientos contigo he aprendido el valor del esfuerzo, sacrificio y la constancia. Cada dia un poco más, cada día suma. Muchos veranos de sacrificio... y los que quedan.',
        avatar: Paula,
    },
    {
        name: 'Izaskun Turrillas',
        role: 'Campeona nacional de salto de altura.',
        content:
            'Soy Izaskun Turrillas, campeona nacional de salto de altura y medallista desde hace varios años. Si algo me ha enseñado Swan durante todo este tiempo es la importancia del compromiso, el trabajo y el esfuerzo diario. "Porque quien curra, después cobra". Y él mejor que nadie sabe de cobrar resultados.',
        avatar: Izaskun,
    },
];

const AvatarStyled = styled(Avatar)`
    img {
        object-position: ${props =>  props.name === 'Paula Suárez' ? "top" : "inherit"}
    }
`;

const backgrounds = [
    `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23ED64A6' /%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%23F56565' /%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%2338B2AC' /%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23ED8936' /%3E%3C/svg%3E")`,
    `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ED8936'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%2348BB78'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%230BC5EA'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%23ED64A6'/%3E%3C/svg%3E")`,
    `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='102.633' cy='61.0737' rx='102.633' ry='61.0737' fill='%23ED8936'/%3E%3Cellipse cx='399.573' cy='123.926' rx='102.633' ry='61.0737' fill='%2348BB78'/%3E%3Cellipse cx='366.192' cy='73.2292' rx='193.808' ry='73.2292' fill='%230BC5EA'/%3E%3Cellipse cx='222.705' cy='110.585' rx='193.808' ry='73.2292' fill='%23ED64A6'/%3E%3C/svg%3E")`,
    `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='560' height='185' viewBox='0 0 560 185' fill='none'%3E%3Cellipse cx='457.367' cy='123.926' rx='102.633' ry='61.0737' transform='rotate(-180 457.367 123.926)' fill='%23ECC94B'/%3E%3Cellipse cx='160.427' cy='61.0737' rx='102.633' ry='61.0737' transform='rotate(-180 160.427 61.0737)' fill='%239F7AEA'/%3E%3Cellipse cx='193.808' cy='111.771' rx='193.808' ry='73.2292' transform='rotate(-180 193.808 111.771)' fill='%234299E1'/%3E%3Cellipse cx='337.295' cy='74.415' rx='193.808' ry='73.2292' transform='rotate(-180 337.295 74.415)' fill='%2348BB78'/%3E%3C/svg%3E")`,
];

function TestmonialCard(props) {
    const { name, role, content, avatar, index } = props;
    return (
        <Flex
            boxShadow={'lg'}
            maxW={'640px'}
            direction={{ base: 'column-reverse', md: 'row' }}
            width={'full'}
            rounded={'xl'}
            p={10}
            justifyContent={'space-between'}
            position={'relative'}
            bg={useColorModeValue('white', 'gray.800')}
            _after={{
                content: '""',
                position: 'absolute',
                height: '21px',
                width: '29px',
                left: '35px',
                top: '-10px',
                backgroundSize: 'cover',
                backgroundImage: `url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='21' viewBox='0 0 29 21' fill='none'%3E%3Cpath d='M6.91391 21C4.56659 21 2.81678 20.2152 1.66446 18.6455C0.55482 17.0758 0 15.2515 0 13.1727C0 11.2636 0.405445 9.43939 1.21634 7.7C2.0699 5.91818 3.15821 4.3697 4.48124 3.05454C5.84695 1.69697 7.31935 0.678787 8.89845 0L13.3157 3.24545C11.5659 3.96667 9.98676 4.94242 8.57837 6.17273C7.21266 7.36061 6.25239 8.63333 5.69757 9.99091L6.01766 10.1818C6.27373 10.0121 6.55114 9.88485 6.84989 9.8C7.19132 9.71515 7.63944 9.67273 8.19426 9.67273C9.34658 9.67273 10.4776 10.097 11.5872 10.9455C12.7395 11.7939 13.3157 13.1091 13.3157 14.8909C13.3157 16.8848 12.6542 18.4121 11.3311 19.4727C10.0508 20.4909 8.57837 21 6.91391 21ZM22.5982 21C20.2509 21 18.5011 20.2152 17.3488 18.6455C16.2391 17.0758 15.6843 15.2515 15.6843 13.1727C15.6843 11.2636 16.0898 9.43939 16.9007 7.7C17.7542 5.91818 18.8425 4.3697 20.1656 3.05454C21.5313 1.69697 23.0037 0.678787 24.5828 0L29 3.24545C27.2502 3.96667 25.6711 4.94242 24.2627 6.17273C22.897 7.36061 21.9367 8.63333 21.3819 9.99091L21.702 10.1818C21.9581 10.0121 22.2355 9.88485 22.5342 9.8C22.8756 9.71515 23.3238 9.67273 23.8786 9.67273C25.0309 9.67273 26.1619 10.097 27.2715 10.9455C28.4238 11.7939 29 13.1091 29 14.8909C29 16.8848 28.3385 18.4121 27.0155 19.4727C25.7351 20.4909 24.2627 21 22.5982 21Z' fill='%239F7AEA'/%3E%3C/svg%3E")`,
            }}
            _before={{
                content: '""',
                position: 'absolute',
                zIndex: '-1',
                height: 'full',
                maxW: '640px',
                width: 'full',
                filter: 'blur(40px)',
                transform: 'scale(0.98)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                top: 0,
                left: 0,
                backgroundImage: backgrounds[index % 4],
            }}
        >
            <Flex direction={'column'} textAlign={'left'} justifyContent={'space-between'}>
                <chakra.p fontWeight={'medium'} fontSize={'15px'} pb={4}>
                    {content}
                </chakra.p>
                <chakra.p fontWeight={'bold'} fontSize={14}>
                    {name}
                    <chakra.span fontWeight={'medium'} color={'gray.500'}>
                        {' '}
                        - {role}
                    </chakra.span>
                </chakra.p>
            </Flex>
            <AvatarStyled src={avatar} name={name}  height={'100px'} width={'100px'} alignSelf={'center'} m={{ base: '0 0 35px 0', md: '0 0 0 50px' }} />
        </Flex>
    );
}

export default function Clients() {
    return (
        <Flex textAlign={'center'} py={10} justifyContent={'center'} direction={'column'} width={'full'}>
            <Box width="full">
                <chakra.h1
                    as={'span'}
                    lineHeight={1.1}
                    fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
                    fontWeight={'black'}
                    color={useColorModeValue('gray.700', 'gray.50')}
                    position={'relative'}
                    w="100%"
                    _after={{
                        content: "''",
                        width: 'full',
                        height: '30%',
                        position: 'absolute',
                        bottom: 1,
                        left: 0,
                        bgGradient: 'linear(to-r, red.400, orange.400)',
                        zIndex: -1,
                    }}
                >
                    ¿QUÉ DICEN DE NOSOTROS?
                </chakra.h1><br />
                <Text as={'span'} fontWeight={'black'} fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }} color={'#d75408'} fontStyle="italic" width="100%">
                    Testimonios de nuestros clientes
                </Text>
            </Box>
            <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={'20'} mt={16} mx={'auto'}>
                {testimonials.map((cardInfo, index) => (
                    <TestmonialCard {...cardInfo} index={index} />
                ))}
            </SimpleGrid>
        </Flex>
    );
}
