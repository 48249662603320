import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import PaymentMethod from "./PaymentMethod";
import Library from "../../../library";
import { useSelector } from "react-redux";


const PaymentContainer = () => {
	const [cardValues, setCardValues] = useState({
		cvc: "",
		expiry: "",
		focus: "",
		name: " ",
		number: "",
	});

	const [loading, setLoading] = useState(false);

	const [currentCard, setCurrentCard] = useState();

	const user = useSelector((state) => state.user);

	const handleSaveCard = async () => {
		setCardValues({ ...cardValues, focus: "" });
		const cardMonth = cardValues.expiry.substring(0, 2);
		const cardYear = cardValues.expiry.substring(2, 4);

		const payload = { cvc: cardValues.cvc, cardMonth, cardNumber: cardValues.number, cardYear, userId: user?.databaseId };

		await Library.createCard(payload);
	};

	useEffect(() => {
		const getCard = async () => {
			if (user) {
				setLoading(true);
				const {
					retrieveSource: { source, brand, exp_month, exp_year, last4 },
				} = await Library.getCard({
					userId: user.databaseId,
				});
				
				if (source && brand && exp_month && exp_year && last4) {
					const month = exp_month.toString().length === 1 ? `0${exp_month}` : exp_month;


					setCurrentCard({
						brand,
						number: `************${last4}`,
						expiry: `${month}${String(exp_year).substring(2, 4)}`,
						source
					});
				}

				setLoading(false);
			}
		};

		getCard();
	}, [user, setCardValues]);

	return (
		<Box
			display="flex"
			justifyContent={{ base: "center", md: "start" }}
			mt="3rem"
			ml={{ base: "0", lg: "3rem" }}
			lo
		>
			<Box
				mt={{ base: "1rem", md: "0" }}
				rounded={5}
				display="flex"
				borderRadius="xl"
				borderColor="gray.100"
				backgroundColor={{ base: "transparent", md: "gray.100" }}
				color={"gray.500"}
			>
				<PaymentMethod
					preview
					issuer={currentCard?.brand}
					cardValues={cardValues}
					setCardValues={setCardValues}
					currentCard={currentCard}
					saveCard={handleSaveCard}
					loading={loading}
				/>
			</Box>
		</Box>
	);
};

export default PaymentContainer;
