import React from "react";
import { useSelector } from "react-redux";
import { Container, Text, Button, Stack, Tag, TagLabel } from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router"

const statusMap = {
	pending: {
		message: "Pendiente",
		color: "#f99500",
	},
	active: {
		message: "Activo",
		color: "#4FAC01",
	},
	cancelled: {
		message: "Cancelado",
		color: "#c13838",
	},
};

const SubscriptionScreen = () => {
	const memberships = useSelector((state) => state.user.memberships);

	return (
		<Container maxW="100%" mt="2rem">
			{!!memberships.length ? (
				memberships.map((membership, index) => (
					<Stack display="flex" flexDir="row" alignItems="center" key={index}>
						<Text fontSize="1.5rem" key={index} marginRight="1rem">
							{`${membership?.name}:`}
						</Text>
						<Tag
                            bgColor={statusMap[membership?.status].color} 
							fontSize="1.5rem"
						>
                            <TagLabel color="whitesmoke">{statusMap[membership?.status]?.message}</TagLabel>
						</Tag>
					</Stack>
				))
			) : (
				<>
					<Text fontSize="1.5rem">No tienes suscripciones</Text>
					<Button
						fontWeight="600"
						color="white"
						bgGradient="linear(to-r, red.400, orange.400)"
						borderRadius="0.5rem"
						mt="5rem"
						fontSize="1rem"
                        as={ReachLink}
                        to="/planes"
					>
						SUSCRIBIRSE
					</Button>
				</>
			)}
		</Container>
	);
};

export default SubscriptionScreen;
