import {
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Flex,
	Container,
	Stack,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
import useSubscriptionInfo from "../../hooks/useSubscriptionInfo"
import FreeTrialBar from "../Authentication/FreeTrialBar/FreeTrialBar";
import Default from "../../components/Layout/Default";
import GACELA from "../../assets/gacela.jpg";
import LEON from "../../assets/leon.jpg";
import LOBO from "../../assets/lobo.jpg";
import OSO from "../../assets/oso.jpg";
import FLAMENCO from "../../assets/flamenco.jpg";
import { navigate } from "@reach/router";
import Course from "./Course";

const ChallengesPlaylist = ({ name }) => {
    const { subscriptionStatus, daysLeft } = useSubscriptionInfo();
	
	if (subscriptionStatus === "expired") {
		navigate(`/planes`);
	}

	const rows = [
		{
			id: "leon",
			animal: (
				<Text
					fontSize="2xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="green.500"
				>
					León
				</Text>
			),
			fuerza: 5,
			resistencia: 1,
			potencia: 1,
			equilibrio: 2,
			actividades: "ENTRENAMIENTO DE FUERZA, CORE, ESTIRAMIENTOS",
			quien: "MUJERES, PERSONAS CON SOBREPESO, DEPORTES DE FUERZA",
		},
		{
			id: "lobo",
			animal: (
				<Text
					fontSize="2xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="blue.500"
				>
					Lobo
				</Text>
			),
			fuerza: 1,
			resistencia: 5,
			potencia: 1,
			equilibrio: 2,
			actividades: "HIIT,CICLO INDOOR, CORE, ESTIRAMIENTOS",
			quien: "BAJAR VOLUMEN, CORREDORES, SEDENTARIOS",
		},
		{
			id: "gacela",
			animal: (
				<Text
					fontSize="2xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="red.500"
				>
					Gacela
				</Text>
			),
			fuerza: 3,
			resistencia: 2,
			potencia: 5,
			equilibrio: 2,
			actividades: "FUERZA, HIIT, CORE, FUERZA EXPLOSVA, ESTIRAMIENTOS",
			quien: "DEPORTISTAS",
		},
		{
			id: "oso",
			animal: (
				<Text
					fontSize="2xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="yellow.500"
				>
					Oso
				</Text>
			),
			fuerza: 2,
			resistencia: 3,
			potencia: 1,
			equilibrio: 3,
			actividades: "TODAS LAS ACTIVIDADES",
			quien: "PERSONA QUE BUSCA FORMA FÍSICA GENERAL Y DISFRUTE",
		},
		{
			id: "flamenco",
			animal: (
				<Text
					fontSize="2xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="green.500"
				>
					Flamenco
				</Text>
			),
			fuerza: 2,
			resistencia: 1,
			potencia: 1,
			equilibrio: 5,
			actividades: "CORE, ESTIRAMIENTOS, PILATES",
			quien: "TODOS",
		},
	];

	const types = {
		leon: 3,
		lobo: 1,
		gacela: 0,
		oso: 2,
		flamenco: 4,
	};

	return (
		<>
			<Default hiddenHero>
                <FreeTrialBar daysLeft={daysLeft} subscriptionStatus={subscriptionStatus} />
				<Container
					mx={0}
					px={0}
					justify="center"
					as={Flex}
					maxW={"full"}
					py={{ base: 4, sm: 10, lg: 16 }}
					bg={useColorModeValue("gray.50", "gray.900")}
				>
					<Stack
						maxWidth="100%"
						bg="rgba(255,255,255, .5)"
						rounded={{ base: "sm", md: "xl" }}
						boxShadow="lg"
						p={{ base: 2, sm: 6, md: 8 }}
						spacing={{ base: 2, md: 8 }}
						h="100%"
						w="90%"
					>
						<Tabs
							maxWidth="100%"
							isFitted
							variant="soft-rounded"
							colorScheme="green"
							orientation={"horizontal"}
							defaultIndex={types[name || "gacela"]}
						>
							<TabList
								as={Flex}
								maxWidth="100%"
								flexWrap={{ base: "wrap", lg: "nowrap" }}
							>
								<Tab rounded="md">Gacela</Tab>
								<Tab rounded="md">Lobo</Tab>
								<Tab rounded="md">Oso</Tab>
								<Tab rounded="md">León</Tab>
								<Tab rounded="md">Flamenco</Tab>
							</TabList>
							<TabPanels>
								<TabPanel px={{ base: 0, md: "inherit" }}>
									<Course
										rows={rows.filter((row) => row.id === "gacela")}
										src={GACELA}
										uri="https://vimeo.com/showcase/7744708/embed"
										title="Gacela"
									/>
								</TabPanel>
								<TabPanel px={{ base: 0, md: "inherit" }}>
									<Course
										rows={rows.filter((row) => row.id === "lobo")}
										src={LOBO}
										uri="https://vimeo.com/showcase/7744730/embed"
										title="Lobo"
									/>
								</TabPanel>
								<TabPanel px={{ base: 0, md: "inherit" }}>
									<Course
										rows={rows.filter((row) => row.id === "oso")}
										src={OSO}
										uri="https://vimeo.com/showcase/8239178/embed"
										title="Oso"
									/>
								</TabPanel>
								<TabPanel px={{ base: 0, md: "inherit" }}>
									<Course
										rows={rows.filter((row) => row.id === "leon")}
										src={LEON}
										uri="https://vimeo.com/showcase/7744703/embed"
										title="León"
									/>
								</TabPanel>
								<TabPanel px={{ base: 0, md: "inherit" }}>
									<Course
										rows={rows.filter((row) => row.id === "flamenco")}
										src={FLAMENCO}
										uri="https://vimeo.com/showcase/7744713/embed"
										title="Flamenco"
									/>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Stack>
				</Container>
			</Default>
		</>
	);
};

export default ChallengesPlaylist;
