export const states = [
    { value: "C", name: "A Coruña" },
    { value: "VI", name: "Araba/Álava" },
    { value: "AB", name: "Albacete" },
    { value: "A", name: "Alicante" },
    { value: "AL", name: "Almería" },
    { value: "O", name: "Asturias" },
    { value: "AV", name: "Ávila" },
    { value: "BA", name: "Badajoz" },
    { value: "PM", name: "Baleares" },
    { value: "B", name: "Barcelona" },
    { value: "BU", name: "Burgos" },
    { value: "CC", name: "Cáceres" },
    { value: "CA", name: "Cádiz" },
    { value: "S", name: "Cantabria" },
    { value: "CS", name: "Castellón" },
    { value: "CE", name: "Ceuta" },
    { value: "CR", name: "Ciudad Real" },
    { value: "CO", name: "Córdoba" },
    { value: "CU", name: "Cuenca" },
    { value: "GI", name: "Girona" },
    { value: "GR", name: "Granada" },
    { value: "GU", name: "Guadalajara" },
    { value: "SS", name: "Gipuzkoa" },
    { value: "H", name: "Huelva" },
    { value: "HU", name: "Huesca" },
    { value: "J", name: "Jaén" },
    { value: "LO", name: "La Rioja" },
    { value: "GC", name: "Las Palmas" },
    { value: "LE", name: "León" },
    { value: "L", name: "Lleida" },
    { value: "LU", name: "Lugo" },
    { value: "M", name: "Madrid" },
    { value: "MA", name: "Málaga" },
    { value: "ML", name: "Melilla" },
    { value: "MU", name: "Murcia" },
    { value: "NA", name: "Navarra" },
    { value: "OR", name: "Ourense" },
    { value: "P", name: "Palencia" },
    { value: "PO", name: "Pontevedra" },
    { value: "SA", name: "Salamanca" },
    { value: "TF", name: "Santa Cruz de Tenerife" },
    { value: "SG", name: "Segovia" },
    { value: "SE", name: "Sevilla" },
    { value: "SO", name: "Soria" },
    { value: "T", name: "Tarragona" },
    { value: "TE", name: "Teruel" },
    { value: "TO", name: "Toledo" },
    { value: "V", name: "Valencia" },
    { value: "VA", name: "Valladolid" },
    { value: "BI", name: "Bizkaia" },
    { value: "ZA", name: "Zamora" },
    { value: "Z", name: "Zaragoza" }
];