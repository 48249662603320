import { gql } from '@apollo/client';

export const GET_MENU = gql`
    query GetMenu {
        menu(id: "dGVybToyMw==", idType: ID) {
            menuItems {
                nodes {
                  label
                  path
                  id
                }
            }
        }
    }
`;

export const GET_PAGE_BY = (slug) => gql`
    query GetView {
        postBy(slug: "${slug}") {
            title
            slug
            content(format: RENDERED)
        }
    }
`;


export const SETTINGS = gql`
    query Settings {
        allSettings {
            generalSettingsDescription
            generalSettingsTitle
        }
    }
`;
