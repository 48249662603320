import {
	ApolloClient,
	ApolloLink,
	gql,
	HttpLink,
	InMemoryCache,
} from "@apollo/client";
import {
	CANCEL_MEMBERSHIP,
	CHANGE_PASSWORD,
	GET_CUSTOMER,
	GET_USER,
	LOGIN_USER,
	REFRESH_AUTH_TOKEN,
	REGISTER_CUSTOMER,
	SEND_PASSWORD_RESET_LINK,
	UPDATE_CUSTOMER,
} from "./authentication";
import {
	ADD_TO_CART,
	CHECKOUT,
	CLEAR_CART,
	GET_CART,
	GET_CHECKOUT_DATA,
	REMOVE_FROM_CART,
	UPDATE_QUANTITY_FROM_CART,
	APPLY_COUPON_TO_CART,
} from "./cart";
import { CREATE_CARD, RETRIEVE_CARD } from "./card";
import { GET_MENU, GET_PAGE_BY, SETTINGS } from "./menu";
import { setContext } from "@apollo/client/link/context";
import _ from "lodash";
import { SEND_CONTACT_FORM } from "./contact";
import { GET_PRODUCTS } from "./products";

const httpLink = new HttpLink({
	uri: "https://api.entrenaconswan.com/graphql",
});

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
export const middleware = new ApolloLink((operation, forward) => {
	/**
	 * If session data exist in local storage, set value as session header.
	 */
	const session = localStorage.getItem("woo-session");
	if (session) {
		operation.setContext(({ headers = {} }) => ({
			headers: {
				"woocommerce-session": `Session ${session}`,
			},
		}));
	}

	return forward(operation);
});

/**
 * Afterware operation
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {
	return forward(operation).map((response) => {
		/**
		 * Check for session header and update session in local storage accordingly.
		 */
		const context = operation.getContext();
		const {
			response: { headers },
		} = context;
		const session = headers.get("woocommerce-session");
		if (session) {
			if (localStorage.getItem("woo-session") !== session) {
				localStorage.setItem("woo-session", headers.get("woocommerce-session"));
			}
		}

		return response;
	});
});

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem("authToken");
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

export default class Library {
	static client = new ApolloClient({
		cache: new InMemoryCache(),
		link: middleware.concat(afterware.concat(authLink.concat(httpLink))),
		headers: { "Content-Type": "application/json" },
	});

	static clientWithoutToken = new ApolloClient({
		cache: new InMemoryCache(),
		link: middleware.concat(afterware.concat(httpLink)),
		headers: { "Content-Type": "application/json" },
	});

	static getData = async (query, noCache = false) => {
		const request = await Library.client.query({
			fetchPolicy: noCache ? "no-cache" : null,
			query: gql`
				${query}
			`,
		});
		const { data } = request;
		return data;
	};

	/**
	 * @description Method that executes a mutation
	 * @param {string} mutation Mutation string
	 */
	static executeMutation = async (mutation, variables = {}, withoutToken) => {
		try {
			const mutationQuery = gql`
				${mutation}
			`;

			let request;

			if (withoutToken) {
				request = await Library.clientWithoutToken.mutate({
					mutation: mutationQuery,
					variables,
				});
			} else {
				request = await Library.client.mutate({
					mutation: mutationQuery,
					variables,
				});
			}

			const { data } = request;
			return data;
		} catch (error) {
			throw error
		}
	};

	static getMenu = async () => {
		const req = await Library.getData(GET_MENU);
		let data = req.menu?.menuItems?.nodes;
		return data;
	};

	static getView = async (slug) => {
		const req = await Library.getData(GET_PAGE_BY(slug));
		let data = req.postBy;
		return data;
	};

	static getSettings = async () => {
		const req = await Library.getData(SETTINGS);
		let data = req.allSettings;
		return data;
	};

	/**
	 * @description Method that will add a product to the cart.
	 * @param {number} productId
	 * @param {number} quantity
	 */
	static addToCart = async (productId, quantity) => {
		const req = await Library.executeMutation(ADD_TO_CART(), {
			productId,
			quantity,
		});
		return req;
	};

	/**
	 * @description Method that gets the current cart.
	 */
	static getCart = async () => {
		const { cart } = await Library.getData(GET_CART(), true);
		return cart;
	};

	/**
	 * @description Method that will remove an item from the cart.
	 * @param {string} cartKey The key of the cart item
	 */
	static removeFromCart = async (cartKey) => {
		const req = await Library.executeMutation(REMOVE_FROM_CART(), {
			cartItemKey: cartKey,
		});
		return req;
	};

	/**
	 * @description Method that will update a quantity from the cart
	 * @param {string} cartKey The key of the cart item
	 * @param {number} quantity The quantity to be set
	 */
	static updateQuantityFromCart = async (cartKey, quantity) => {
		const req = await Library.executeMutation(UPDATE_QUANTITY_FROM_CART(), {
			quantity,
			cartKey,
		});

		return req;
	};

	/**
	 * @description Method that creates an order and returns the payment gateway response
	 * @param {any} billing Billing address object
	 * @param {any} shipping Shipping address object
	 * @param {string} paymentGateway The payment gateway to be used for the payout process
	 * @param {any} metaData any required metadata
	 * @param {any} account The account to be created with the registration
	 */
	static doCheckout = async (
		billing,
		shipping,
		paymentGateway,
		metaData,
		account
	) => {
		const req = await Library.executeMutation(CHECKOUT(), {
			input: {
				billing,
				shipping,
				isPaid: false,
				paymentMethod: paymentGateway,
				shipToDifferentAddress: false,
				transactionId: _.uniqueId(),
				shippingMethod: "flexible_shipping",
				metaData,
			},
		});

		return req;
	};

	/**
	 * @description Method to authenticate an user.
	 * @param {string} username
	 * @param {string} password
	 */
	static doLogin = async (username, password) => {
		const req = await Library.executeMutation(LOGIN_USER(username, password));
		return req;
	};

	/**
	 * @description Method to register an user
	 * @param {string} username
	 * @param {string} password
	 * @param {string} email
	 */
	static doRegister = async (username, password, email) => {
		/* const user = await Library.executeMutation(
	  REGISTER_USER(username, password, email)
	); */

		const req = await Library.executeMutation(
			REGISTER_CUSTOMER(username, password, email)
		);
		return req;
	};

	/**
	 * @description Methdo to refresh the auth token
	 * @param {string} refreshToken
	 */
	static refreshAuthToken = async (refreshToken) => {
		const req = await Library.executeMutation(
			REFRESH_AUTH_TOKEN(refreshToken),
			{},
			true
		);

		return req;
	};

	/**
	 * @description Method to get the currently authenticated user
	 */
	static getUser = async () => {
		const req = await Library.getData(GET_USER());
		return req;
	};

	static getCustomer = async (customerId) => {
		const req = await Library.getData(GET_CUSTOMER(customerId));
		return req;
	};

	static updateCustomer = async (customerData) => {
		const req = await Library.executeMutation(UPDATE_CUSTOMER, customerData);
		return req;
	};
	/**
	 * @description Method to get the shipping method and the payment gateways
	 */
	static getCheckoutData = async () => {
		const req = await Library.getData(GET_CHECKOUT_DATA());
		return req;
	};

	/**
	 * @description Method to send the contact form data.
	 * @param {{ email: string, message: string, name: string, phone: string }} params Contact form data
	 * @returns {{ success: boolean, data: any }}
	 */
	static sendContactForm = async (params) => {
		const req = await Library.executeMutation(SEND_CONTACT_FORM(), {
			...params,
		});

		return req;
	};

	/**
	 * @description It clears the cart.
	 * @returns
	 */
	static clearCart = async () => {
		const req = await Library.executeMutation(CLEAR_CART());
		return req;
	};

	static getPlans = async () => {
		const {
			products: { nodes },
		} = await Library.getData(GET_PRODUCTS());

		return nodes;
	};

	/**
	 * @name Library.changePassword
	 * @description Method to change the password of an user
	 */
	static changePassword = async (newPassword, newPasswordRepeat) => {
		const req = await Library.executeMutation(
			CHANGE_PASSWORD(newPassword, newPasswordRepeat)
		);

		return req;
	};

	static sendPasswordResetLink = async (email) => {
		const req = await Library.executeMutation(SEND_PASSWORD_RESET_LINK(email));
		return req;
	};

	static cancelMembership = async () => {
		const req = await Library.executeMutation(CANCEL_MEMBERSHIP);
		return req;
	};

	// Cards

	static createCard = async (params) => {
		const req = await Library.executeMutation(CREATE_CARD(params));
		return req;
	};

	static getCard = async ({ userId }) => {
		const req = await Library.executeMutation(RETRIEVE_CARD(userId));
		return req;
	};

	static applyCoupon = async (coupon) => {
		try {
			const req = await Library.executeMutation(APPLY_COUPON_TO_CART(coupon));
			return req;
		} catch (error) {
			return { error };
		}
	};
}
