import Registry from "./Registry";
import { applyMiddleware, createStore as createReduxStore } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import persistReducer from "redux-persist/es/persistReducer";

const persistConfig = {
  key: "main",
  storage: storage,
};

/**
 * Class that handles the API used for redux actions.
 */
class ReduxAPI {
  /**
   * @description Method that creates a root reducer with all the registered actions.
   */
  static createRootReducer() {
    const actions = Registry.getActions();

    return (state = {}, action) => {
      const { type, payload, newState } = action;

      if (type === "MUTATE") {
        return newState;
      }

      const currentAction = actions[type];

      if (!currentAction) {
        return state;
      }

      return currentAction.action(state, payload);
    };
  }

  /**
   * @description Method that creates the main store
   */
  static createStore() {
    const rootReducer = this.createRootReducer();
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = createReduxStore(persistedReducer, applyMiddleware(thunk));
    const persistor = persistStore(store);

    return { store, persistor };
  }
}

export default ReduxAPI;
