import React from 'react'
import {
	Text,
	Input,
} from "@chakra-ui/react";

const NameContainer = ({ setFormValues, formValues }) => {
    return (
        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Text fontSize={{ base: "sm", md: "md" }} fontWeight="600" color="#D75408">
                        Nombre <b style={{ color: "red" }}>*</b>
                    </Text>
                </div>

                <Input
                    bg={"gray.100"}
                    border={0}
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                        fontSize: { base: "xs", md: "sm" },
                    }}
                    mt="0.3rem"
                    placeholder="Añade tu nombre"
                    maxW="20rem"
                    onChange={(e) => setFormValues({ ...formValues, firstName: e.target.value })}
                    value={formValues?.firstName}
                />

            </div>
            <div  style={{ marginLeft: '0.5rem'}}>
                <div
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Text fontSize={{ base: "sm", md: "md" }} fontWeight="600" color="#D75408">
                        Apellido <b style={{ color: "red" }}>*</b>
                    </Text>
                </div>
                <Input
                    bg={"gray.100"}
                    border={0}
                    color={"black"}
                    _placeholder={{
                        color: "gray.500",
                        fontSize: { base: "xs", md: "sm" },
                    }}
                    mt="0.3rem"
                    placeholder="Añade tu apellido"
                    onChange={(e) => setFormValues({ ...formValues, lastName: e.target.value })}
                    value={formValues?.lastName}
                />
            </div>
        </div>
    )
}

export default NameContainer