import {
    Box,
    Flex,
    Stack,
    Container,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react';
import Default from '../../components/Layout/Default';
import BG from '../../assets/Swan_11.jpg';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { useParams } from '@reach/router';

const Authentication = () => {
    const { tab } = useParams();
    return (
        <Default hiddenHero>
            <Box
                position={'relative'}
                bgSize="60%"
                bgPosition={{ base: "100% 40%", md: "100% 100%", lg: "100% 40%"}}
                bgRepeat="no-repeat"
                bgImage={{ md: `url(${BG})`}}
                h={{ base: "auto", md: "100vh", lg: "auto" }}
            >
                <Container as={Flex} maxW={'7xl'} py={{ base: 10, sm: 20, md: 24, lg: 32 }} justifyContent={{ md: "center", lg: "flex-start" }}>
                    <Stack
                        bg="rgba(255,255,255, .5)"
                        rounded={'xl'}
                        boxShadow="lg"
                        p={{ base: 4, sm: 6, md: 8 }}
                        spacing={{ base: 8 }}
                        w="lg"
                    >
                        <Tabs variant="soft-rounded" colorScheme="green" defaultIndex={tab === 'registro' ? 1 : 0}>
                            <TabList>
                                <Tab>Acceder</Tab>
                                <Tab>Regístrate</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <SignIn />
                                </TabPanel>
                                <TabPanel>
                                    <SignUp />
                                </TabPanel>
                                <TabPanel>
                                   {/* <SignUp />  */}
                                </TabPanel> 
                            </TabPanels>
                        </Tabs>
                    </Stack>
                </Container>
            </Box>
        </Default>
    );
};
export default Authentication;
