import { Flex, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Box, Text } from '@chakra-ui/react';
import React from 'react';
import { StarIcon } from '@chakra-ui/icons';
import { ResponsiveCard } from '../Plans';

const Course = ({ src, uri, title, rows }) => {
    return (
        <>
            

            <Flex w="100%" justifyContent="center" py={8} flexWrap="wrap">
                <Box
                    as="iframe"
                    src={uri}
                    allowFullScreen
                    frameBorder="0"
                    title={title}
                    maxWidth="100%"
                    width="720px"
                    height={{ base: '200px', md: '400px' }}
                ></Box>
                {rows && rows.map((row) => <ResponsiveCard key={row.label} row={row} />)}
                <Box w="100%" overflowX={{ base: 'scroll', md: 'auto' }}>
                    <Table variant="simple" w="100%" my={8} display={{ base: 'none', lg: 'inherit' }}>
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th>Fuerza</Th>
                                <Th>Resistencia</Th>
                                <Th>Potencia</Th>
                                <Th>Equilibrio</Th>
                                <Th>Actividades</Th>
                                <Th>Para Quien</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rows &&
                                rows.map((row) => (
                                    <Tr key={row.animal}>
                                        <Row row={row} />
                                    </Tr>
                                ))}
                            <Tr></Tr>
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th></Th>
                                <Th>Fuerza</Th>
                                <Th>Resistencia</Th>
                                <Th>Potencia</Th>
                                <Th>Equilibrio</Th>
                                <Th>Actividades</Th>
                                <Th>Para Quien</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </Box>

                {/* <Image w="300px" h="300px" objectFit="contain" src={src} /> */}
            </Flex>
        </>
    );
};

const Stars = ({ count }) => {
    return (
        <>
            {' '}
            {Array(5)
                .fill('')
                .map((_, i) => (
                    <StarIcon key={i} color={i < count ? 'yellow.500' : 'gray.300'} />
                ))}{' '}
        </>
    );
};

const Row = ({ row }) => {
    return (
        <>
            <Td>
                <Flex mt="2" direction="column" alignItems="center">
                    <Text fontSize="1.50rem" fontStyle="italic" fontWeight="semibold">
                        {row.animal}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Box d="flex" mt="2" alignItems="center">
                    <Stars count={row.fuerza} />
                </Box>
            </Td>
            <Td>
                <Box d="flex" mt="2" alignItems="center">
                    <Stars count={row.resistencia} />
                </Box>
            </Td>
            <Td>
                <Box d="flex" mt="2" alignItems="center">
                    <Stars count={row.potencia} />
                </Box>
            </Td>
            <Td>
                <Box d="flex" mt="2" alignItems="center">
                    <Stars count={row.equilibrio} />
                </Box>
            </Td>
            <Td>{row.actividades}</Td>
            <Td>{row.quien}</Td>
        </>
    );
};

export default Course;
