import React from 'react'
import {
    Box,
} from "@chakra-ui/react";
import PaymentMethod from '../Profile/Screen/PaymentMethod';

const CardInputs = ({ cardValues, setCardValues, currentCard, saveCard, loading }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            rounded={5}
            borderRadius="xl"
            borderColor="gray.100"
            backgroundColor={{ base: 'transparent', md: "gray.100"}}
            color={"gray.500"}
            mb="1rem"
        >
            <PaymentMethod preview={true} cardValues={cardValues} setCardValues={setCardValues} currentCard={currentCard} loading={loading} saveCard={saveCard} />
        </Box>
    )
}

export default CardInputs