import React from 'react'
import {
    Text,
    Box,
    Textarea
} from "@chakra-ui/react";

const NotesContainer = ({ formData, setFormData }) => {
    return (
        <Box>
            <Text fontSize={{ base: "sm", md: "md" }} fontWeight="600" color="#D75408" mb="0.3rem">
                Notas del pedido (opcional)
            </Text>
            <Textarea
                placeholder="Notas sobre tu pedido"
                _placeholder={{ color: 'gray.500', fontSize: { base: "xs", md: "sm" }, }}
                backgroundColor="gray.100"
                minH="104px"
                value={formData?.additionalInfo}
            />
        </Box>
    )
}

export default NotesContainer