import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box } from '@chakra-ui/layout';
import React from 'react';
import Default from '../../components/Layout/Default';
import Clients from './Clients';
import OurPlatform from './OurPlatform';
import Plans from './Plans';

const Home = () => {
    return (
        <Default>
            <Box as="main">
                <Box bg={useColorModeValue("gray.50", "gray.900")} pt={16} px={{base: 8, lg: 32}}>
                <OurPlatform />
                </Box>
                    
                <Box px={{base: 8, lg: 32}}>
                <Plans />
                </Box>

                <Box px={{base: 8, lg: 32}}>
                <Clients />
                </Box>
            </Box>
        </Default>
    );
};

export default Home;
