import React, { useState } from "react";
import {
  Box,
  Stack,
  Heading,
  Input,
  Button,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import isNullOrEmpty from "../../library/api/utils/isNullOrEmpty";
import handleChange from "../../library/api/utils/handleChange";
import Library from "../../library";
const SignIn = () => {
  const [formData, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const setFormData = (field, value) =>
    setData({ ...formData, [field]: value });

  const toast = useToast();

  const send = async () => {
    try {
      setLoading(true);
      await Library.sendContactForm(formData);
      toast({
        title: "¡Exito!",
        description:
          "Tu mensaje ha sivo enviado. Te responderemos a la mayor brevedad.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setData({ email: "", phone: "", message: "", name: "" });
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Ha ocurrido un error al iniciar sesión. Intentalo de nuevo.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
          fontWeight="black"
        >
          ¡CONTÁCTANOS!
        </Heading>
      </Stack>
      <Box as={"form"} mt={10}>
        <Stack spacing={4}>
          <Input
            value={formData.name}
            onChange={handleChange((val) => setFormData("name", val))}
            placeholder="Nombre"
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
          />
          <Input
            value={formData.email}
            onChange={handleChange((val) => setFormData("email", val))}
            placeholder="Correo Electrónico"
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
          />
          <Input
            value={formData.phone}
            onChange={handleChange((val) => setFormData("phone", val))}
            placeholder="Teléfono"
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
          />
          <Textarea
            value={formData.message}
            onChange={handleChange((val) => setFormData("message", val))}
            placeholder="Consulta"
            bg={"gray.100"}
            border={0}
            color={"gray.500"}
            _placeholder={{
              color: "gray.500",
            }}
          />
        </Stack>
        <Button
          onClick={send}
          isLoading={loading}
          loadingText="Enviando..."
          disabled={
            isNullOrEmpty(formData.email) ||
            isNullOrEmpty(formData.name) ||
            isNullOrEmpty(formData.phone) ||
            isNullOrEmpty(formData.message)
          }
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400, orange.400)"
          color={"white"}
          _hover={{
            bgGradient: "linear(to-r, red.400, orange.400)",
            boxShadow: "xl",
          }}
        >
          Enviar
        </Button>
      </Box>
    </>
  );
};

export default SignIn;
