import {
	Box,
	Stack,
	HStack,
	Heading,
	Text,
	VStack,
	useColorModeValue,
	List,
	ListItem,
	ListIcon,
	Button,
	useToast,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import useSubscriptionStatus from "../../hooks/useSubscriptionInfo";
import usePlans from "../../redux/hooks/usePlans";
import FreeTrialBar from "../Authentication/FreeTrialBar/FreeTrialBar";

function PriceWrapper({ children }) {
	return (
		<Box
			mb={4}
			shadow="base"
			borderWidth="1px"
			alignSelf={{ base: "center", lg: "flex-start" }}
			borderColor={useColorModeValue("gray.200", "gray.500")}
			borderRadius={"xl"}
		>
			{children}
		</Box>
	);
}

export default function Subscriptions() {
	const [loading, setLoading] = useState({});
	const { subscriptionStatus, daysLeft } = useSubscriptionStatus();
	const { addPlanToCart, isPlanAlreadyInCart } = usePlans();

	const toast = useToast();

	// const user = useAuthentication();

	// const setAfterLoginNavigation = useAction('setAfterLoginNavigation');

	const doAddPlanToCart = async (slug) => {
		try {
			setLoading({ [slug]: true });

			await addPlanToCart(slug);

			// if (!user) {
			//     toast({
			//         status: 'error',
			//         title: 'Error',
			//         description: 'Debes iniciar sesión para poder inscribirte al plan',
			//     });

			//     await setAfterLoginNavigation({ path: '/finalizar-compra' });
			//     navigate('/acceder');
			//     return;
			// }
		} catch (error) {
			toast({
				title: "Error",
				description:
					"Ha ocurrido un error al intentar agregar el plan al carrito. Por favor, inténtalo de nuevo.",
				status: "error",
			});
		} finally {
			setLoading({ [slug]: false });
			navigate("/carrito");
		}
	};

	return (
		<Box py={0}>
			<Box pb="2rem">
				<FreeTrialBar
					subscriptionStatus={subscriptionStatus}
					daysLeft={daysLeft}
				/>
			</Box>
			<VStack spacing={2} textAlign="center">
				<Heading as="h1" fontSize="4xl" fontWeight={"black"}>
					Nuestros planes y retos.
				</Heading>
			</VStack>

			<Stack
				direction={{ base: "column", lg: "row" }}
				textAlign="center"
				justify="center"
				spacing={{ base: 4, lg: 10 }}
				py={10}
			>
				<PriceWrapper>
					<Box py={4} px={12}>
						<Text fontWeight="500" fontSize="2xl">
							Plan Básico
						</Text>
						<HStack justifyContent="center">
							<Text fontSize="5xl" fontWeight="900">
								19,99
							</Text>
							<Text fontSize="3xl" fontWeight="black">
								€
							</Text>
							<Text fontSize="3xl" color="gray.500">
								/mes
							</Text>
						</HStack>
					</Box>
					<VStack
						bg={useColorModeValue("gray.50", "gray.700")}
						py={4}
						borderBottomRadius={"xl"}
					>
						<List spacing={3} textAlign="start" px={12}>
							<ListItem>
								<ListIcon as={FaCheckCircle} color="green.500" />
								Acceso ilimitado a los entrenamientos por retos.
							</ListItem>
						</List>
						<Box w="80%" pt={7}>
							<Button
								disabled={isPlanAlreadyInCart("plan-basico")}
								isLoading={loading["plan-basico"]}
								onClick={() => doAddPlanToCart("plan-basico")}
								bgGradient="linear(to-r, blue.500, blue.300)"
								_hover={{
									bgGradient: "linear(to-r, blue.500, blue.300)",
									boxShadow: "xl",
								}}
								w="full"
								color="#fff"
								variant="outline"
							>
								{isPlanAlreadyInCart("plan-basico")
									? "Ya agregado al carrito"
									: "Comprar ya!"}
							</Button>
						</Box>
					</VStack>
				</PriceWrapper>

				<PriceWrapper>
					<Box position="relative">
						<Box
							position="absolute"
							top="-16px"
							left="50%"
							style={{ transform: "translate(-50%)" }}
						>
							<Text
								textTransform="uppercase"
								bg={useColorModeValue("orange.500", "orange.700")}
								px={3}
								py={1}
								color={useColorModeValue("gray.900", "gray.300")}
								fontSize="sm"
								fontWeight="black"
								rounded="xl"
							>
								Más popular
							</Text>
						</Box>
						<Box py={4} px={12}>
							<Text fontWeight="500" fontSize="2xl">
								Plan Básico + Entrenamiento personal
							</Text>
							<HStack justifyContent="center">
								<Text fontSize="5xl" fontWeight="900">
									49,99
								</Text>
								<Text fontSize="3xl" fontWeight="black">
									€
								</Text>
								<Text fontSize="3xl" color="gray.500">
									/mes
								</Text>
							</HStack>
						</Box>
						<VStack
							bg={useColorModeValue("gray.50", "gray.700")}
							py={4}
							borderBottomRadius={"xl"}
						>
							<List spacing={3} textAlign="start" px={12}>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />
									Acceso ilimitado a los entrenamientos por retos.
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />1
									Entrenamiento personal al mes.
								</ListItem>
							</List>
							<Box w="80%" pt={7}>
								<Button
									disabled={isPlanAlreadyInCart(
										"plan-basico-entrenamiento-personal"
									)}
									isLoading={loading["plan-basico-entrenamiento-personal"]}
									onClick={() =>
										doAddPlanToCart("plan-basico-entrenamiento-personal")
									}
									bgGradient="linear(to-r, blue.500, blue.300)"
									_hover={{
										bgGradient: "linear(to-r, blue.500, blue.300)",
										boxShadow: "xl",
									}}
									w="full"
									color="#fff"
								>
									{isPlanAlreadyInCart("plan-basico-entrenamiento-personal")
										? "Ya agregado al carrito"
										: "Comprar ya!"}
								</Button>
							</Box>
						</VStack>
					</Box>
				</PriceWrapper>

				<PriceWrapper>
					<Box py={4} px={12}>
						<Text fontWeight="500" fontSize="2xl">
							Plan a Medida
						</Text>
						<HStack justifyContent="center">
							<Text fontSize="2xl" fontWeight="900">
								Precio a consultar
							</Text>
						</HStack>
					</Box>
					<VStack
						bg={useColorModeValue("gray.50", "gray.700")}
						py={4}
						borderBottomRadius={"xl"}
					>
						<List spacing={3} textAlign="start" px={12}>
							<ListItem>
								<ListIcon as={FaCheckCircle} color="green.500" />
								Acceso ilimitado a los entrenamientos por retos.
							</ListItem>
						</List>
						<Box w="80%" pt={7}>
							<Button
								onClick={() => navigate("/contacto")}
								bgGradient="linear(to-r, blue.500, blue.300)"
								_hover={{
									bgGradient: "linear(to-r, blue.500, blue.300)",
									boxShadow: "xl",
								}}
								w="full"
								color="#fff"
								variant="outline"
							>
								Contáctanos
							</Button>
						</Box>
					</VStack>
				</PriceWrapper>
			</Stack>
		</Box>
	);
}
