import { gql } from "@apollo/client";

export const ADD_TO_CART = () => gql`
  mutation AddProductToCart($productId: Int!, $quantity: Int!) {
    addToCart(input: { productId: $productId, quantity: $quantity }) {
      cart {
        contents {
          nodes {
            total
            tax
            subtotalTax
            subtotal
            quantity
            product {
              node {
                databaseId
                name
                ... on SimpleProduct {
                  id
                  name
                  price
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_FROM_CART = () => gql`
  mutation RemoveItemFromCart($cartItemKey: [ID]) {
    __typename
    removeItemsFromCart(input: { keys: $cartItemKey, all: false }) {
      cart {
        contents {
          nodes {
            key
          }
        }
      }
    }
  }
`;

export const UPDATE_QUANTITY_FROM_CART = () => gql`
  mutation UpdateQuantityFromCart {
    __typename
    updateItemQuantities(
      input: { items: { quantity: $quantity, key: $cartKey } }
    ) {
      cart {
        contents {
          nodes {
            key
          }
        }
      }
    }
  }
`;

export const GET_CART = () => gql`
  query GetCartContent {
    cart {
      contents {
        nodes {
          key
          total
          tax
          subtotalTax
          subtotal
          quantity
          product {
            node {
              databaseId
              name
            }
          }
        }
      }
      contentsTotal
      discountTotal
      feeTotal
      shippingTotal
      subtotalTax
      totalTax
      subtotal
      total
    }
  }
`;

export const CHECKOUT = () => gql`
  mutation CHECKOUT_MUTATION($input: CheckoutInput!) {
    checkout(input: $input) {
      clientMutationId
      order {
        id
        databaseId
        orderKey
        dateCompleted
        total
        refunds {
          nodes {
            amount
          }
        }
        status
      }
      customer {
        jwtAuthToken
        jwtRefreshToken
        id
        displayName
        email
      }
      result
      redirect
    }
  }
`;

export const GET_CHECKOUT_DATA = () => gql`
  query GetCheckoutData {
    paymentGateways {
      nodes {
        icon
        id
        title
      }
    }
  }
`;

export const CLEAR_CART = () => gql`
  mutation ClearCart {
    __typename
    emptyCart(input: { clearPersistentCart: false }) {
      clientMutationId
    }
  }
`;

export const APPLY_COUPON_TO_CART = (code) => gql`
  mutation ApplyCoupon {
    __typename
    applyCoupon(input: { code: "${code}" }) {
      clientMutationId
      cart {
        appliedCoupons {
          nodes {
            amount
          }
        }
      }
    }
  }
`;
