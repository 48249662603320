import React, { useState } from "react";
import {
	Box,
	Stack,
	Heading,
	Input,
	Button,
	Select,
	useToast,
	SimpleGrid,
	Text,
} from "@chakra-ui/react";
import Order from "./Order";
import useAction from "../../redux/api/hooks/useAction";
import { useEffect } from "react";
import handleChange from "../../library/api/utils/handleChange";
import Library from "../../library";
import isNullOrEmpty from "../../library/api/utils/isNullOrEmpty";
import useAuthentication from "../../redux/hooks/useAuthentication";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import { navigate } from "@reach/router";
// import SignUp from "../Authentication/SignUp";

const CheckoutFields = () => {
	// const toast = useToast();

	// const doSaveUser = useAction("login");
	const refreshUser = useAction("refreshUser");

	const user = useAuthentication();

	const [formData, setData] = useState({ email: user?.email });
	const [loading, setLoading] = useState(false);
	const [paymentGateways, setPaymentGateways] = useState([]);
	const [paymentGateway, setPaymentGateway] = useState([]);

	// SignUp Fields
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [passwordRepeat, setPasswordRepeat] = useState("");

	const refreshCart = useAction("refreshCart");
	const doSaveOrder = useAction("saveRecentlyCreatedOrder");

	const stripe = useStripe();
	const elements = useElements();

	const toast = useToast();

	const initialize = async () => {
		setLoading(true);
		try {
			const cart = await Library.getCart();

			await refreshCart({ cart });
			const response = await Library.getCheckoutData();
			const {
				paymentGateways: { nodes: paymentGateways },
			} = response;

			setPaymentGateways(paymentGateways);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	const doCheckout = async () => {
		setLoading(true);

		try {
			if (isNullOrEmpty(paymentGateway)) {
				toast({
					status: "error",
					title: "Error",
					description: "Debes introducir un método de pago para continuar.",
				});
				return;
			}

			if (password !== passwordRepeat) {
				toast({
					status: "error",
					title: "Error",
					description:
						"Las contraseñas no coinciden. Por favor, asegúrate de que has colocado las contraseñas correctamente.",
				});
				return;
			}

			const metaData = [];

			if (paymentGateway === "stripe") {
				const cardElement = elements.getElement(CardElement);

				const {
					source: { id: sourceId },
				} = await stripe.createSource(cardElement, {
					type: `card`,
				});

				metaData.push({
					key: `_stripe_source_id`,
					value: sourceId,
				});
			}

			const { checkout } = await Library.doCheckout(
				formData,
				formData,
				paymentGateway,
				metaData,
				{
					username,
					password,
				}
			);

			const { result } = checkout;

			if (result !== "success") {
				toast({
					title: "Error",
					description:
						"Ha ocurrido un error al intentar procesar tu orden. Por favor, contacte con un administrador.",
					status: "error",
					duration: 3000,
					isClosable: true,
				});

				return;
			}

			await doSaveOrder(checkout);

			const {
				customer: { jwtAuthToken, jwtRefreshToken },
			} = checkout;

			// Refresh the user so we have the membership loaded
			await refreshUser({
				authToken: jwtAuthToken,
				refreshToken: jwtRefreshToken,
				navigateTo: "/pedido-realizado",
			});
		} catch (error) {
			console.error(error);
			const {
				graphQLErrors: [{ message }],
			} = error;

			toast({
				title: "Error",
				description: message,
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		initialize();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Stack spacing={4}>
				<Heading
					color={"gray.800"}
					lineHeight={1.1}
					fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
					fontWeight="black"
				>
					¡Suscríbete!
				</Heading>
				<Text>
					El pago de la suscripción se carga el primer día de cada mes, si te
					registras otro día se te cargará la parte proporciónal del mes.
				</Text>
			</Stack>

			{/* <SignUp /> */}
			<Box as={"form"} mt={10}>
				<SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
					<Input
						onChange={handleChange((val) => setUsername(val))}
						placeholder="Nombre de usuario"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) =>
							setData({ ...formData, email: val })
						)}
						value={formData.email}
						placeholder="Correo Electrónico"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) => setPassword(val))}
						placeholder="Contraseña"
						type="password"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) => setPasswordRepeat(val))}
						placeholder="Repite la contraseña"
						type="password"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>

					<Input
						onChange={handleChange((val) =>
							setData({ ...formData, firstName: val })
						)}
						value={formData.firstName}
						placeholder="Nombre"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) =>
							setData({ ...formData, lastName: val })
						)}
						value={formData.lastName}
						placeholder="Apellido"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>

					<Input
						onChange={handleChange((val) =>
							setData({ ...formData, phone: val })
						)}
						value={formData.phone}
						placeholder="Teléfono"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Select
						onChange={handleChange((val) =>
							setData({ ...formData, state: val })
						)}
						value={formData.state}
						placeholder="Provincia"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					>
						<option value="C">A Coruña</option>
						<option value="VI">Araba/Álava</option>
						<option value="AB">Albacete</option>
						<option value="A">Alicante</option>
						<option value="AL">Almería</option>
						<option value="O">Asturias</option>
						<option value="AV">Ávila</option>
						<option value="BA">Badajoz</option>
						<option value="PM">Baleares</option>
						<option value="B">Barcelona</option>
						<option value="BU">Burgos</option>
						<option value="CC">Cáceres</option>
						<option value="CA">Cádiz</option>
						<option value="S">Cantabria</option>
						<option value="CS">Castellón</option>
						<option value="CE">Ceuta</option>
						<option value="CR">Ciudad Real</option>
						<option value="CO">Córdoba</option>
						<option value="CU">Cuenca</option>
						<option value="GI">Girona</option>
						<option value="GR">Granada</option>
						<option value="GU">Guadalajara</option>
						<option value="SS">Gipuzkoa</option>
						<option value="H">Huelva</option>
						<option value="HU">Huesca</option>
						<option value="J">Jaén</option>
						<option value="LO">La Rioja</option>
						<option value="GC">Las Palmas</option>
						<option value="LE">León</option>
						<option value="L">Lleida</option>
						<option value="LU">Lugo</option>
						<option value="M">Madrid</option>
						<option value="MA">Málaga</option>
						<option value="ML">Melilla</option>
						<option value="MU">Murcia</option>
						<option value="NA">Navarra</option>
						<option value="OR">Ourense</option>
						<option value="P">Palencia</option>
						<option value="PO">Pontevedra</option>
						<option value="SA">Salamanca</option>
						<option value="TF">Santa Cruz de Tenerife</option>
						<option value="SG">Segovia</option>
						<option value="SE">Sevilla</option>
						<option value="SO">Soria</option>
						<option value="T">Tarragona</option>
						<option value="TE">Teruel</option>
						<option value="TO">Toledo</option>
						<option value="V">Valencia</option>
						<option value="VA">Valladolid</option>
						<option value="BI">Bizkaia</option>
						<option value="ZA">Zamora</option>
						<option value="Z">Zaragoza</option>
					</Select>
					<Input
						onChange={handleChange((val) =>
							setData({ ...formData, city: val })
						)}
						value={formData.city}
						placeholder="Localidad / Ciudad"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) =>
							setData({ ...formData, address1: val })
						)}
						value={formData.address1}
						placeholder="Dirección"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) =>
							setData({ ...formData, postcode: val })
						)}
						value={formData.postcode}
						placeholder="CP"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Select
						onChange={handleChange((val) => setPaymentGateway(val))}
						value={paymentGateway}
						placeholder="Método de pago"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					>
						{paymentGateways?.map((item) => (
							<option value={item.id}>{item.title}</option>
						))}
					</Select>
				</SimpleGrid>
				<Order />
				{paymentGateway === "stripe" ? (
					<Box
						borderWidth={1}
						rounded={5}
						my={4}
						p={4}
						borderColor="gray.100"
						color={"gray.500"}
					>
						<CardElement />
					</Box>
				) : null}
				<Button
					disabled={
						!username ||
						username === "" ||
						!password ||
						password === "" ||
						(password && password !== passwordRepeat) ||
						(paymentGateway === "stripe" && (!stripe || !elements))
					}
					onClick={() => doCheckout()}
					isLoading={loading}
					loadingText="Cargando..."
					mt={8}
					w={"full"}
					bgGradient="linear(to-r, red.400, orange.400)"
					color={"white"}
					_hover={{
						bgGradient: "linear(to-r, red.400, orange.400)",
						boxShadow: "xl",
					}}
				>
					Finalizar Compra
				</Button>
			</Box>
		</>
	);
};

export default CheckoutFields;
