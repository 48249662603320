import React, { useState } from "react";
import Library from "../../library";
import useAction from "../../redux/api/hooks/useAction";
import { Text, Input, Box, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { CheckIcon } from "@chakra-ui/icons";

const Coupon = () => {
	const [coupon, setCoupon] = useState("");
	const [disable, setDisable] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const refreshCart = useAction("refreshCart");
	const {
		discountTotal,
	} = useSelector((state) => state.cart);

	const handleCouponChange = (e) => {
		setCoupon(e.target.value);
	};

	// create regular expresion for this string ¡El cupón «as» no existe!
	const regex = /^¡El cupón «(.*)» no existe!$/;

	const handleCouponSubmit = async () => {
		setLoading(true);
		if (!!coupon.length) {
			try {
				const response = await Library.applyCoupon(coupon.toLocaleLowerCase());
				if (regex.test(response.error?.message)) {
					setError(true);
					setLoading(false);
					return;
				}

				if (
					!!response?.applyCoupon?.cart?.appliedCoupons?.nodes[0]?.amount &&
					response?.applyCoupon?.cart?.appliedCoupons?.nodes[0]?.amount !== 0
				) {
					setError(false);
					setDisable(true);
					const currentCart = await Library.getCart();
					await refreshCart({ cart: currentCart });
				}
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
		setLoading(false);
	};

	if (disable || Number(discountTotal.replace("&nbsp;€", "")) !== 0) {
		return (
			<Box
				flexDir="row"
				style={{ display: "flex" }}
				justifyContent="center"
				alignItems="center"
				backgroundColor="gray.100"
				padding={3}
				borderRadius={5}
				mb={{ base: 5, md: 0 }}
			>
				<CheckIcon color="green.600" mr="0.5rem" w={6} h={6} />
				<Text>Cupón Aplicado</Text>
			</Box>
		);
	}

	return (
		<Box
			backgroundColor="gray.100"
			padding={3}
			borderRadius={5}
			mb={{ base: 5, md: 0 }}
		>
			<Input
				onChange={handleCouponChange}
				placeholder="Cupón"
				bg={"white.100"}
				border={0}
				value={coupon}
				color={"gray.500"}
				_placeholder={{
					color: "gray.500",
				}}
			/>
			{error && (
				<Text color="red.500" textAlign="center" mt="0.5rem" fontWeight="bold">
					El cupón no existe!
				</Text>
			)}
			<Button
				isLoading={loading}
				disabled={loading}
				onClick={handleCouponSubmit}
				mt={5}
				w={"full"}
				bgGradient="linear(to-r, red.400, orange.400)"
				color={"white"}
				_hover={{
					bgGradient: "linear(to-r, red.400, orange.400)",
					boxShadow: "xl",
				}}
			>
				Aplicar Cupón
			</Button>
		</Box>
	);
};

export default Coupon;
