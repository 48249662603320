import React, { useEffect, useState } from "react";
import {
	Text,
	Container,
	Box,
	Divider,
	Button,
	useToast,
} from "@chakra-ui/react";
import Default from "../../components/Layout/Default";
import Order from "../Checkout/Order";
import { useSelector } from "react-redux";
import Library from "../../library";
import useAction from "../../redux/api/hooks/useAction";
import NameContainer from "./NameContainer";
import DirectionInputs from "./DirectionInputs";
import NotesContainer from "./NotesContainer";
import CardInputs from "./CardInputs";
import Coupon from "./Coupon";

const Cart = () => {
	const user = useSelector((state) => state.user);
	const billing = user && user.billing;
	const [cardLoading, setCardLoading] = useState(false);

	const [loading, setLoading] = useState(false);

	const [formValues, setFormValues] = useState({
		firstName: user?.firstName || "",
		lastName: user?.lastName || "",
		address1: billing?.address1 || "",
		address2: billing?.address2 || "",
		city: billing?.city || "",
		company: billing?.company || "",
		phone: billing?.phone || "",
		postcode: billing?.postcode || "",
		state: billing?.state || "",
	});

	const [cardValues, setCardValues] = useState({
		cvc: "",
		expiry: "",
		focus: "",
		name: " ",
		number: "",
	});

	const [currentCard, setCurrentCard] = useState();

	const disable =
		!formValues.firstName ||
		!formValues.lastName ||
		!formValues.address1 ||
		!formValues.city ||
		!formValues.postcode ||
		!formValues.state ||
		!currentCard ||
		!!cardLoading;

	const toast = useToast();

	const refreshCart = useAction("refreshCart");
	const doSaveOrder = useAction("saveRecentlyCreatedOrder");
	const refreshUser = useAction("refreshUser");

	const handleSubmit = async () => {
		setLoading(true);

		const thisbilling = {
			...formValues,
			email: user.email,
		};

		delete thisbilling.__typename;

		try {
			//

			const {
				retrieveSource: { source, stripe_customer_id },
			} = await Library.getCard({
				userId: user.databaseId,
			});

			const metaData = [
				{
					key: `_stripe_source_id`,
					value: source,
				},
				{
					key: `_stripe_customer_id`,
					value: stripe_customer_id,
				},
			];
			//
			const { checkout } = await Library.doCheckout(
				thisbilling,
				thisbilling,
				"stripe",
				metaData,
				{}
			);

			const { result } = checkout;

			if (result !== "success") {
				toast({
					title: "Error",
					description:
						"Ha ocurrido un error al intentar procesar tu orden. Por favor, contacte con un administrador.",
					status: "error",
					duration: 3000,
					isClosable: true,
				});

				return;
			}

			await doSaveOrder(checkout);

			const {
				customer: { jwtAuthToken, jwtRefreshToken },
			} = checkout;

			// Refresh the user so we have the membership loaded

			const currentCart = await Library.getCart();

			await refreshCart({ cart: currentCart });

			await refreshUser({
				authToken: jwtAuthToken,
				refreshToken: jwtRefreshToken,
				navigateTo: "/pedido-realizado",
			});
		} catch (error) {
			console.error(error);
			const {
				graphQLErrors: [{ message }],
			} = error;

			toast({
				title: "Error",
				description: message,
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const getCard = async () => {
			if (user) {
				setCardLoading(true);
				const {
					retrieveSource: { source, brand, exp_month, exp_year, last4 },
				} = await Library.getCard({
					userId: user.databaseId,
				});

				if (source && brand && exp_month && exp_year && last4) {
					const month =
						exp_month.toString().length === 1 ? `0${exp_month}` : exp_month;

					setCurrentCard({
						brand,
						number: `************${last4}`,
						expiry: `${month}${String(exp_year).substring(2, 4)}`,
						source,
					});
				}
				setCardLoading(false);
			}
		};

		getCard();
	}, [user]);

	const handleSaveCard = async () => {
		setCardLoading(true);
		setCardValues({ ...cardValues, focus: "" });
		const cardMonth = cardValues.expiry.substring(0, 2);
		const cardYear = cardValues.expiry.substring(2, 4);

		const payload = {
			cvc: cardValues.cvc,
			cardMonth,
			cardNumber: cardValues.number,
			cardYear,
			userId: user?.databaseId,
		};

		const {
			createSource: { source },
		} = await Library.createCard(payload);

		setCurrentCard({
			number: cardValues.number,
			source,
		});
		setCardLoading(false);
	};

	return (
		<Default hiddenHero>
			<Container maxW="100ch">
				<Text fontSize="3xl" fontWeight="medium" textAlign="start" mb="0.5rem">
					Finalizar compra
				</Text>
				<Divider />
				<Box
					display="flex"
					flexDir={{ base: "column", md: "row" }}
					justifyContent="space-between"
					width="100%"
					maxW="unset"
				>
					<Box mb="2rem">
						<Text
							fontSize="lg"
							fontWeight="semibold"
							textAlign="start"
							mt="1rem"
							mb="1rem"
						>
							DETALLES DE FACTURACIÓN
						</Text>
						<NameContainer
							setFormValues={setFormValues}
							formValues={formValues}
						/>
						<Divider h="1rem" />
						<DirectionInputs
							setFormData={setFormValues}
							formData={formValues}
						/>
					</Box>
					<Box w={{ base: "100%", md: "50%" }} ml={{ base: "0", md: "2rem" }}>
						<Text fontSize="lg" fontWeight="semibold" mt="1rem" mb="1rem">
							INFORMACIÓN ADICIONAL
						</Text>
						<NotesContainer formData={formValues} setFormData={setFormValues} />
						<Box
							mt="1.5rem"
							display="flex"
							flexDir="column"
							justifyContent="center"
						>
							<Text
								fontSize="lg"
								fontWeight="semibold"
								textAlign="start"
								mb="1rem"
							>
								MÉTODO DE PAGO
							</Text>
							<CardInputs
								loading={cardLoading}
								currentCard={currentCard}
								cardValues={cardValues}
								setCardValues={setCardValues}
								saveCard={handleSaveCard}
							/>
						</Box>
                        <Coupon />
					</Box>
				</Box>
				<Box width={{ base: "100%", md: "60%" }} mb="3rem">
					<Text
						fontSize="3xl"
						fontWeight="medium"
						textAlign="start"
						mb="0.5rem"
					>
						Mi pedido
					</Text>
					<Divider />
					<Order />
					<Button
						disabled={disable}
						onClick={() => handleSubmit()}
						isLoading={loading || cardLoading}
						loadingText="Cargando..."
						mt={8}
						w={"full"}
						bgGradient="linear(to-r, red.400, orange.400)"
						color={"white"}
						_hover={{
							bgGradient: "linear(to-r, red.400, orange.400)",
							boxShadow: "xl",
						}}
					>
						Finalizar Compra
					</Button>
				</Box>
			</Container>
		</Default>
	);
};

export default Cart;
