import { gql } from "@apollo/client";

export const GET_PRODUCTS = () => gql`
  query GetProducts {
    products {
      nodes {
        id
        slug
        name
        databaseId
        description
        shortDescription
        ... on SubscriptionProduct {
          regularPrice
        }
      }
    }
  }
`;
