import { Box, Flex, HStack, Link, IconButton, Button, Menu, MenuButton, MenuList, MenuItem, useDisclosure, Text, Tag, TagLabel } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, AtSignIcon } from '@chakra-ui/icons';
import Logo from './Logo';
import { Link as ReachLink } from '@reach/router';
import useAuthentication from '../redux/hooks/useAuthentication';
import { FaShoppingCart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import useSubscriptionStatus from '../hooks/useSubscriptionInfo';

const Links = [
    { label: 'Inicio', pathLink: '/' },
    { label: 'Contacto', pathLink: '/contacto' },
];

const NavLink = ({ children, pathLink }) => (
    <Link
        px={2}
        py={1}
        as={ReachLink}
        rounded={'md'}
        _hover={{ textDecoration: 'none', bg: 'gray.200', color: '#d75408' }}
        to={pathLink}
        fontWeight="medium"
        fontSize="1.25rem"
    >
        {children}
    </Link>
);

export default function NavBar() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const user = useAuthentication();

    const cartItems = useSelector(state => state.cart?.contents?.nodes);
    const { subscriptionStatus } = useSubscriptionStatus();
    const hiddenChangelles = subscriptionStatus !== 'active' && subscriptionStatus !== 'freeTrial';
    return (
        <>
            <Box bg="white" px={{ base: 8, lg: 24 }}>
                <Flex h={24} alignItems={'center'} justifyContent={'space-between'}>
                    <Menu>
                        <MenuButton
                            onClick={isOpen ? onClose : onOpen}
                            size={'md'}
                            as={Button}
                            rounded={'full'}
                            variant={'link'}
                            cursor={'pointer'}
                            display={{ md: !isOpen ? 'none' : 'inherit' }}
                        >
                            <IconButton
                                rounded="md"
                                bgGradient="linear(to-r, red.400, orange.400)"
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400, orange.400)',
                                    boxShadow: 'xl',
                                }}
                                color="white"
                                icon={!isOpen ? <HamburgerIcon /> : <CloseIcon />}
                            />
                        </MenuButton>
                        <MenuList>
                            {Links.map(link => (
                                <MenuItem as={ReachLink} key={link.label} to={link.pathLink}>
                                    {link.label}
                                </MenuItem>
                            ))}

                            <MenuItem as={ReachLink} to="/planes">
                                Planes
                            </MenuItem>

                            {user && !hiddenChangelles && (
                                <MenuItem as={ReachLink} to="/retos">
                                    Retos
                                </MenuItem>
                            )}
                        </MenuList>
                    </Menu>

                    <HStack spacing={8} alignItems={'center'}>
                        <Box as={ReachLink} to="/" w={{ md: '250px', base: '120px' }}>
                            <Logo />
                        </Box>
                        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                            {Links.map(link => (
                                <NavLink key={link.label} pathLink={link.pathLink}>
                                    {link.label}
                                </NavLink>
                            ))}
                            <NavLink pathLink="/planes">Planes</NavLink>
                            {user && !hiddenChangelles && <NavLink pathLink="/retos">Retos</NavLink>}
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        {!user ? (
                            <Button
                                variant={'solid'}
                                bgGradient="linear(to-r, red.400, orange.400)"
                                _hover={{
                                    bgGradient: 'linear(to-r, red.400, orange.400)',
                                    boxShadow: 'xl',
                                }}
                                color="white"
                                size={'sm'}
                                mr={4}
                                as={ReachLink}
                                leftIcon={<AtSignIcon />}
                                to="/acceder"
                            >
                                <Text display={{ base: 'none', md: 'inherit' }}>Acceder</Text>
                            </Button>
                        ) : null}
                        {user ? (
                            <>
                                {!user.memberships.length && (
                                    <Box ml="1rem" mr="1rem" position="relative" as={ReachLink} to="/carrito/">
                                        <Button as={Button} bgColor="white" rounded={'full'} variant={'link'} cursor={'pointer'}>
                                            <IconButton rounded="full" variant="outline" colorScheme="red" aria-label="Send email" icon={<FaShoppingCart />} />
                                        </Button>
                                        {!!cartItems.length && (
                                            <Tag colorScheme="red" size="sm" textAlign="center" position="absolute" top="-0.2rem" right="-0.6rem">
                                                <TagLabel textAlign="center">{cartItems.length}</TagLabel>
                                            </Tag>
                                        )}
                                    </Box>
                                )}
                                <Button
                                    variant={'solid'}
                                    bgGradient="linear(to-r, red.400, orange.400)"
                                    _hover={{
                                        bgGradient: 'linear(to-r, red.400, orange.400)',
                                        boxShadow: 'xl',
                                    }}
                                    color="white"
                                    size={'sm'}
                                    mr={4}
                                    as={ReachLink}
                                    leftIcon={<AtSignIcon />}
                                    to="/perfil/"
                                >
                                    <Text display={{ base: 'none', md: 'inherit' }}>Mi Cuenta</Text>
                                </Button>
                            </>
                        ) : null}
                    </Flex>
                </Flex>
            </Box>
        </>
    );
}
