import {
	Wrap,
	WrapItem,
	Text,
	Heading,
	Box,
	Flex,
	Button,
} from "@chakra-ui/react";
import React from "react";
import Card from "../../components/Cards/Card";

import GACELA from "../../assets/gacela.jpg";
import LEON from "../../assets/leon.jpg";
import LOBO from "../../assets/lobo.jpg";
import OSO from "../../assets/oso.jpg";
import FLAMENCO from "../../assets/flamenco.jpg";
import { Link } from "@reach/router";
import useAuthentication from "../../redux/hooks/useAuthentication";

const Plans = () => {
	const user = useAuthentication();
	const rows = [
		{
			id: "leon",
			animal: (
				<Text
					fontSize="4xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="brown"
				>
					León
				</Text>
			),
			fuerza: 5,
			resistencia: 1,
			potencia: 1,
			equilibrio: 2,
			actividades: "ENTRENAMIENTO DE FUERZA, CORE, ESTIRAMIENTOS",
			quien: "MUJERES, PERSONAS CON SOBREPESO, DEPORTES DE FUERZA",
		},
		{
			id: "lobo",
			animal: (
				<Text
					fontSize="4xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="red.500"
				>
					Lobo
				</Text>
			),
			fuerza: 1,
			resistencia: 5,
			potencia: 1,
			equilibrio: 2,
			actividades: "HIIT,CICLO INDOOR, CORE, ESTIRAMIENTOS",
			quien: "BAJAR VOLUMEN, CORREDORES, SEDENTARIOS",
		},
		{
			id: "gacela",
			animal: (
				<Text
					fontSize="4xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="green.500"
				>
					Gacela
				</Text>
			),
			fuerza: 3,
			resistencia: 2,
			potencia: 5,
			equilibrio: 2,
			actividades: "FUERZA, HIIT, CORE, FUERZA EXPLOSVA, ESTIRAMIENTOS",
			quien: "DEPORTISTAS",
		},
		{
			id: "oso",
			animal: (
				<Text
					fontSize="4xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="yellow.500"
				>
					Oso
				</Text>
			),
			fuerza: 2,
			resistencia: 3,
			potencia: 1,
			equilibrio: 3,
			actividades: "TODAS LAS ACTIVIDADES",
			quien: "PERSONA QUE BUSCA FORMA FÍSICA GENERAL Y DISFRUTE",
		},
		{
			id: "flamenco",
			animal: (
				<Text
					fontSize="4xl"
					textAlign="center"
					fontWeight="black"
					fontStyle="italic"
					color="pink.500"
				>
					Flamenco
				</Text>
			),
			fuerza: 2,
			resistencia: 1,
			potencia: 1,
			equilibrio: 5,
			actividades: "CORE, ESTIRAMIENTOS, PILATES",
			quien: "TODOS",
		},
	];

	return (
		<Flex
			as="section"
			direction="column"
			alignItems="center"
			py={{ md: 16, lg: 32 }}
		>
			<Heading
				lineHeight={1.1}
				textAlign="center"
				fontWeight="black"
				fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
			>
				<Text
					as={"span"}
					position={"relative"}
					_after={{
						content: "''",
						width: "full",
						height: "30%",
						position: "absolute",
						bottom: 1,
						left: 0,
						zIndex: -1,
						bgGradient: "linear(to-r, red.400, orange.400)",
					}}
				>
					NUESTRO SISTEMA
				</Text>
				<br />
				<Text as={"span"} color={"#d75408"} fontStyle="italic">
					Basado en retos
				</Text>
			</Heading>

			<Box py={8} maxW="3xl" textAlign="center">
				<Text as={"span"} fontWeight="600" textAlign="center">
					Hemos creado un sistema de entrenamiento para desarrollar las
					cualidades y habilidades que vas a necesitar para lograr retos
					personales tales como:{" "}
					<Text as={"span"} color={"#d75408"}>
						LA PERDIDA DE VOLUMEN, LA FORMA FISICA, O EL REDIMIENTO DEPORTIVO.
					</Text>{" "}
					Para facilitar la consecución de tus retos, hemos creado RUTINAS DE
					TRABAJOS ESPECÍFICOS orientadas a potenciar tu{" "}
					<Text as={"span"} color={"#d75408"}>
						FUERZA, RESISTENCIA, POTENCIA, EQUILIBRIO.
					</Text>{" "}
					Con esa ultima incluimos la{" "}
					<Text as={"span"} color={"#d75408"}>
						Flexibilidad, y Movilidad articular.
					</Text>
				</Text>
			</Box>
			<Wrap spacing={8} justify="center">
				<WrapItem w={{ base: "100%", md: "45%" }} m={0}>
					<Card
						imgUrl={GACELA}
						row={rows.find((row) => row.id === "gacela")}
						label={
							<Text
								fontSize="2xl"
								textAlign="center"
								fontWeight="black"
								color="green.500"
							>
								Potencia
							</Text>
						}
					/>
				</WrapItem>
				<WrapItem w={{ base: "100%", md: "45%" }} m={0}>
					<Card
						imgUrl={LOBO}
						row={rows.find((row) => row.id === "lobo")}
						label={
							<Text
								fontSize="2xl"
								textAlign="center"
								fontWeight="black"
								color="red.500"
							>
								Resistencia
							</Text>
						}
					/>
				</WrapItem>
				<WrapItem w={{ base: "100%", md: "45%" }} m={0}>
					<Card
						imgUrl={OSO}
						row={rows.find((row) => row.id === "oso")}
						label={
							<Text
								fontSize="2xl"
								textAlign="center"
								fontWeight="black"
								color="yellow.500"
							>
								Ponte en forma
							</Text>
						}
					/>
				</WrapItem>
				<WrapItem w={{ base: "100%", md: "45%" }} m={0}>
					<Card
						imgUrl={LEON}
						row={rows.find((row) => row.id === "leon")}
						label={
							<Text
								fontSize="2xl"
								textAlign="center"
								fontWeight="black"
								color="brown"
							>
								Fuerza
							</Text>
						}
					/>
				</WrapItem>
				<WrapItem w={{ base: "100%", md: "45%" }} m={0}>
					<Card
						imgUrl={FLAMENCO}
						row={rows.find((row) => row.id === "flamenco")}
						label={
							<Text
								fontSize="2xl"
								textAlign="center"
								fontWeight="black"
								color="pink.500"
							>
								Equilibrio
							</Text>
						}
					/>
				</WrapItem>
			</Wrap>
			{!user && (
				<Button
					bgGradient="linear(to-r, red.400, orange.400)"
					_hover={{
						bgGradient: "linear(to-r, red.400, orange.400)",
						boxShadow: "xl",
					}}
					color="white"
					size="lg"
					my={8}
					letterSpacing="1px"
					minW="150px"
					fontSize="1.35rem"
					fontStyle="italic"
					as={Link}
					to="/planes"
				>
					¿Qué plan necesito?
				</Button>
			)}
		</Flex>
	);
};

export default Plans;
