import {
  Box,
  Flex,
  Stack,
  Container,
  Text,
  Table,
  Heading,
  Td,
  Thead,
  Tr,
  Th,
  Tbody,
  Link,
} from "@chakra-ui/react";
import Default from "../../components/Layout/Default";
import BG from "../../assets/Swan_09.jpg";
import { Link as ReachLink, Redirect } from "@reach/router";
import { useSelector } from "react-redux";

const OrderReceived = () => {
  const recentlyCreatedOrder = useSelector(
    (state) => state.recentlyCreatedOrder
  );


  if (!recentlyCreatedOrder) {
    return <Redirect to="/" />;
  }

  const { order } = recentlyCreatedOrder;

  return (
    <Default hiddenHero>
      <Box
        bgSize="contain"
        bgRepeat="no-repeat"
        style={{
          backgroundImage: `url(${BG})`,
        }}
        minH="100vh"
      >
        <Container
          justify="center"
          as={Flex}
          maxW={"7xl"}
          py={{ base: 5, sm: 10, lg: 16 }}
        >
          <Stack
            bg="rgba(255,255,255, .5)"
            rounded={"xl"}
            boxShadow="lg"
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            w="xl"
          >
            <Heading
              color={"gray.800"}
              fontWeight="black"
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              ¡Pedido recibido!
            </Heading>

            <Text>Gracias. Tu pedido ha sido recibido.</Text>

            <Table variant="striped" mt={8}>
              <Thead>
                <Tr>
                  <Th>NÚMERO DEL PEDIDO: </Th>
                  <Th>FECHA: </Th>
                  <Th isNumeric>TOTAL:</Th>
                  <Th>MÉTODO DE PAGO:</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{order.databaseId}</Td>
                  <Td>{order.dateCompleted}</Td>
                  <Td isNumeric>{order.total.replace("&nbsp;", " ")}</Td>
                  <Td>Tarjeta de crédito (Stripe)</Td>
                </Tr>
              </Tbody>
            </Table>

            <Text>
              Ve el estado de tu suscripción en tu{" "}
              <Link color="blue.600" as={ReachLink} to="/perfil">
                cuenta.
              </Link>
            </Text>
          </Stack>
        </Container>
      </Box>
    </Default>
  );
};
export default OrderReceived;
