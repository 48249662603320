import React from "react";
import { Container, Text, Button } from "@chakra-ui/react";
import { navigate } from "@reach/router";

const FreeTrialBar = ({ subscriptionStatus, daysLeft, needsButton, padding= "1rem" }) => {
	if (subscriptionStatus === "active") return null;

	const navigateToPlans = () => {
		navigate(`/planes`);
	};

	const bg = (daysLeft < 1 || subscriptionStatus === 'expired') ? '#FF0000' : '#4FAC01';
	const message = (daysLeft < 1 || subscriptionStatus === 'expired') ? "Tu periodo de prueba ha caducado. Suscríbete para comenzar a entrenar sin límites con Entrena con Swan" : `Estás disfrutando del periodo de prueba, te quedan ${daysLeft} días`;

	return (
		<Container
			width="100%"
			backgroundColor={bg}
			minh="7vh"
			color="white"
			maxW="100%"
			display="flex"
			flexDir={{ base: "column", md: "row" }}
			alignItems="center"
			justifyContent="center"
			textAlign="center"
			p={padding}
		>
			<Text fontSize={{ base: '1rem', sm: "1.2rem" }} fontStyle="italic" fontWeight="semibold">
				{message}
			</Text>
			{needsButton && (subscriptionStatus === "expired" || daysLeft < 1) && (
				<Button
					onClick={navigateToPlans}
					fontWeight="600"
					ml={{ base: "0", md: "1rem" }}
					mt={{ base: "1rem", md: "0" }}
					padding={{ base: "0.5rem 1rem", md: "0.5rem 1.5rem" }}
					color="white"
					bgGradient="linear(to-r, red.400, orange.400)"
					borderRadius="0.5rem"
					fontSize="1rem"
				>
					SUSCRÍBETE
				</Button>
			)}
		</Container>
	);
};

export default FreeTrialBar;
