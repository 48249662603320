import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
	Container,
	Text,
	Button,
	Input,
	IconButton,
	Divider,
} from "@chakra-ui/react";
import SendPasswordResetLinkModal from "../../Authentication/SendPasswordResetLinkModal/SendPasswordResetLinkModal";
import { EditIcon } from "@chakra-ui/icons";
import Library from "../../../library";
import useAction from "../../../redux/api/hooks/useAction";

const ProfileScreen = () => {
	const [showModal, setShowModal] = useState(false);
	const user = useSelector((state) => state.user);
	const [editName, setEditName] = useState(!user.firstName);
	const [editLastName, setEditLastName] = useState(!user.lastName);
	const [loading, setLoading] = useState(false);
	const [formValues, setFormValues] = useState({
		firstName: "",
		lastName: "",
	});
	const updateProfile = useAction("updateProfile");

	const handleNameChange = (e) => {
		setFormValues({ ...formValues, firstName: e.target.value });
	};

	const handleLastNameChange = (e) => {
		setFormValues({ ...formValues, lastName: e.target.value });
	};

	const disable = !formValues.firstName && !formValues.lastName;

	const handleSubmit = async () => {
		setLoading(true);
		if (!!formValues.firstName && !!formValues.lastName) {
			await Library.updateCustomer({ input: { ...formValues, id: user?.id } });
			await updateProfile({
				lastName: formValues.lastName,
				firstName: formValues.firstName,
			});
			setEditLastName(false);
			setEditName(false);
		} else if (!!formValues.firstName) {
			await Library.updateCustomer({
				input: { firstName: formValues.firstName, id: user?.id },
			});
			await updateProfile({ firstName: formValues.firstName });
			setEditName(false);
		} else if (!!formValues.lastName) {
			await Library.updateCustomer({
				input: { lastName: formValues.lastName, id: user?.id },
			});
			await updateProfile({ lastName: formValues.lastName });
			setEditLastName(false);
		}
		setLoading(false);
	};

	return (
		<Container maxW="100%" mt="2rem">
			<SendPasswordResetLinkModal
				isOpened={showModal}
				onClose={() => setShowModal(!showModal)}
				defaultEmail={user?.email}
			/>
			<>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Text fontSize={{ base: "sm", md: "lg" }} fontWeight="600" color="#D75408">
						Nombre:
					</Text>
					<IconButton
						ml="1rem"
						variant="solid"
						colorScheme="orange"
						size="xs"
						icon={<EditIcon />}
						onClick={() => {
							setFormValues({ ...formValues, firstName: "" });
							setEditName(!editName);
						}}
					/>
				</div>
				{editName ? (
					<Input
						bg={"gray.100"}
						border={0}
						color={"black"}
						_placeholder={{
							color: "gray.500",
							fontSize: { base: "xs", md: "sm" },
						}}
						mt="0.3rem"
						placeholder="Añade tu nombre"
						maxW="20rem"
						onChange={handleNameChange}
						value={formValues.firstName}
					/>
				) : (
					<Text fontSize="1.5rem">{user?.firstName}</Text>
				)}
			</>
			<Divider height="1rem" />
			<>
				<div
					style={{ display: "flex", alignItems: "center", marginTop: "1.5rem" }}
				>
					<Text fontSize={{ base: "sm", md: "lg" }} fontWeight="600" color="#D75408">
						Apellido:
					</Text>
					<IconButton
						ml="1rem"
						variant="solid"
						colorScheme="orange"
						size="xs"
						icon={<EditIcon />}
						onClick={() => {
							setFormValues({ ...formValues, lastName: "" });
							setEditLastName(!editLastName);
						}}
					/>
				</div>
				{editLastName ? (
					<Input
						bg={"gray.100"}
						border={0}
						color={"black"}
						_placeholder={{
							color: "gray.500",
							fontSize: { base: "xs", md: "sm" },
						}}
						mt="0.3rem"
						placeholder="Añade tu apellido"
						maxW="20rem"
						onChange={handleLastNameChange}
						value={formValues.lastName}
					/>
				) : (
					<Text fontSize="1.5rem">{user?.lastName}</Text>
				)}
			</>
			<Divider height="1rem" />
			{(!!editLastName || !!editName) && (
				<Button
					onClick={handleSubmit}
					fontWeight="600"
					color="white"
					bgGradient="linear(to-r, red.400, orange.400)"
					borderRadius="0.5rem"
					fontSize="1rem"
					mr="1rem"
					mt="1.5rem"
					disabled={disable}
					isLoading={loading}
				>
					GUARDAR
				</Button>
			)}
			<>
				<Text
					fontSize={{ base: "sm", md: "lg" }}
					mt="1.5rem"
					fontWeight="600"
					width="100%"
					color="#D75408"
				>
					Email:
				</Text>
				<Text fontSize="1.5rem">{user?.email}</Text>
			</>
			<Divider height="1rem" />
			<Button
				onClick={() => setShowModal(!showModal)}
				fontWeight="600"
				color="white"
				mt="1.5rem"
				bgGradient="linear(to-r, red.400, orange.400)"
				borderRadius="0.5rem"
				fontSize="1rem"
			>
				CAMBIAR CONTRASEÑA
			</Button>
		</Container>
	);
};

export default ProfileScreen;
