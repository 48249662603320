import { Center, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, useDisclosure, Button, Text, Flex, useToast } from '@chakra-ui/react';
import { navigate } from '@reach/router';
import { useState } from 'react';
import { useEffect, useReducer } from 'react';
// import useAction from '../../redux/api/hooks/useAction';
// import useAuthentication from '../../redux/hooks/useAuthentication';
import usePlans from '../../redux/hooks/usePlans';
import { Link as ReachLink } from '@reach/router';

const reducer = (state, action) => {
    switch (action.type) {
        case 'increment':
            return { ...state, step: state.step + 1 };
        case 'decrement':
            return { ...state, step: state.step - 1 };
        case 'changeType':
            return {
                ...state,
                step: action.payload.step || 1,
                stepType: action.payload.stepType,
                results: { ...state.results, [action.payload.stepType]: 0 },
            };
        case 'setResult':
            return {
                ...state,
                results: { ...state.results, [state.stepType]: action.payload.value },
            };
        default:
            throw new Error();
    }
};

const planQuestions = [
    {
        title: '¿SUFRES ALGÚN TIPO DE LESIÓN O MOLESTIAS?',
        options: [
            { label: 'No', points: 1 },
            { label: 'Si', points: 2 },
        ],
    },
    {
        title: '¿SUELES CONSEGUIR TUS RETOS?',
        options: [
            { label: 'Siempre', points: 1 },
            { label: 'A veces', points: 2 },
            { label: 'Nunca', points: 3 },
        ],
    },
    {
        title: '¿CUÁL ES TU NIVEL DE MOTIVACIÓN?',
        options: [
            { label: 'Estoy deseando que llegue el momento', points: 1 },
            { label: 'No me cuesta entrenar', points: 2 },
            { label: 'Me cuesta pero acabo entrenando', points: 3 },
            { label: 'Me da pereza', points: 4 },
        ],
    },
    {
        title: '¿QUÉ PLAZO TE MARCAS PARA LOGRAR TU(S) OBJETIVOS?',
        options: [
            { label: 'No tengo plazo', points: 1 },
            { label: '6-12 Meses', points: 2 },
            { label: '3-6 Meses', points: 3 },
            { label: '1-2 Meses', points: 4 },
        ],
    },
    {
        title: '¿CUÁL ES TU OBJETIVO?',
        options: [
            { label: 'Bajar volumen', points: 1 },
            { label: 'Prevenir lesiones', points: 2 },
            { label: 'Tener una forma saludable', points: 3 },
            { label: 'Mejorar tu rendimiento deportivo', points: 4 },
        ],
    },
];

const challengeQuestions = [
    {
        title: '¿PRACTICAS UN DEPORTE O UNA ACTIVIDAD FÍSICA DE TIPO...?',
        options: [
            { label: 'Fuerza', points: 1 },
            { label: 'Resistencia', points: 2 },
            { label: 'Fuerzas explosivas', points: 3 },
            { label: 'Equilibrio', points: 4 },
            { label: 'No practico', points: 5 },
        ],
    },
    {
        title: '¿CUÁL CONSIDERAS TU NIVEL DE FORMA ACTUAL?',
        options: [
            { label: 'Me canso en mi día a día', points: 1 },
            { label: 'Aguanto poco cuando entreno', points: 2 },
            { label: 'Me encuentro bien', points: 3 },
            { label: 'Gozo de una muy buena forma', points: 4 },
        ],
    },
    {
        title: '¿CUÁL CONSIDERAS TU NIVEL DE COORDINACIÓN?',
        options: [
            { label: 'Tengo total dominio de mi cuerpo', points: 1 },
            { label: 'Controlo mi cuerpo', points: 2 },
            { label: 'Me cuesta dominar mi cuerpo', points: 3 },
            { label: 'No domino mi cuerpo', points: 4 },
        ],
    },
    {
        title: '¿CÓMO ES TU DÍA A DÍA?',
        options: [
            { label: 'Paso el día de pie', points: 1 },
            { label: 'Paso el día sentado', points: 2 },
            { label: 'Manejo peso', points: 3 },
        ],
    },
    {
        title: '¿CUÁL ES TU OBJETIVO?',
        options: [
            { label: 'Bajar volumen', points: 1 },
            { label: 'Prevenir lesiones', points: 2 },
            { label: 'Tener una forma saludable', points: 3 },
            { label: 'Mejorar tu rendimiento deportivo', points: 4 },
        ],
    },
];

const initialState = {
    step: 1,
    stepType: 'challenge',
    questions: { challenge: challengeQuestions, plan: planQuestions },
    results: { challenge: 0, plan: 0 },
};

const AutomaticPlan = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { step, stepType, questions, results } = state;
    const [loading, setLoading] = useState(false);
    // const user = useAuthentication();
    // const setAfterLoginNavigation = useAction('setAfterLoginNavigation');

    const toast = useToast();

    const { addPlanToCart, isPlanAlreadyInCart, getPlanBySlug } = usePlans();

    useEffect(() => {
        if (!isOpen) {
            dispatch({ type: 'changeType', payload: { stepType: 'challenge' } });
        }
    }, [isOpen]);

    const doAddPlanToCart = async (slug) => {
        try {
            setLoading(true);
            await addPlanToCart(slug);
            navigate('/finalizar-compra');
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Ha ocurrido un error al intentar agregar el plan al carrito. Por favor, inténtalo de nuevo.',
                status: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const renderStepType = () => {
        if (step === 1) {
            if (stepType === 'challenge') {
                return <First callback={dispatch} />;
            }
        }

        return null;
    };

    const renderResult = () => {
        if (step === 7 && stepType === 'challenge') {
            const totalPoints = results[stepType];
            let resultType;

            if (totalPoints <= 9) {
                resultType = 'Flamenco';
            } else if (totalPoints >= 10 && totalPoints <= 14) {
                resultType = 'Lobo';
            } else if (totalPoints >= 15 && totalPoints <= 19) {
                resultType = 'Oso';
            } else if (totalPoints >= 20 && totalPoints <= 24) {
                resultType = 'León';
            } else if (totalPoints >= 25) {
                resultType = 'Gacela';
            }

            return <Second callback={dispatch} resultType={resultType} />;
        }

        if (step === 7 && stepType === 'plan') {
            const totalPoints = results[stepType];
            let resultType;
            let selectedPlan;

            if (totalPoints <= 7) {
                selectedPlan = 'plan-basico';
            } else if (totalPoints >= 8 && totalPoints <= 15) {
                selectedPlan = 'plan-basico-entrenamiento-personal';
            } else if (totalPoints >= 16) {
                resultType = { planName: 'Plan a medida', price: null };
            }

            if (selectedPlan === null) {
                resultType = { planName: 'Plan a medida', price: null };
            } else {
                const plan = getPlanBySlug(selectedPlan);
                if (!plan) {
                    return null;
                }

                resultType = {
                    planName: plan.name,
                    price: plan.regularPrice,
                    planSlug: plan.slug,
                };
            }

            return (
                <Third
                    loading={loading}
                    resultType={resultType}
                    callback={dispatch}
                    doAddPlanToCart={doAddPlanToCart}
                    isPlanAlreadyInCart={isPlanAlreadyInCart}
                />
            );
        }

        return null;
    };

    if (!stepType) {
        return null;
    }

    return (
        <>
        <Center minW="100%">

            <Button
                bgGradient="linear(to-r, red.400, orange.400)"
                _hover={{
                    bgGradient: 'linear(to-r, red.400, orange.400)',
                    boxShadow: 'xl',
                }}
                color="white"
                size="lg"
                letterSpacing="1px"
                minW="150px"
                fontSize="1rem"
                fontStyle="italic"
                onClick={onOpen}
                >
                ¿Qué animal quieres ser?
            </Button>
                </Center>

            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                <ModalOverlay />
                <ModalContent py={32}>
                    <ModalCloseButton />
                    <ModalBody>
                        {renderStepType()}
                        {renderResult()}
                        {questions[stepType].map(
                            (question, ind) => step === ind + 2 && <StepsFlow results={results[stepType]} callback={dispatch} question={question} />
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

const Third = ({ callback, resultType, doAddPlanToCart, isPlanAlreadyInCart, loading }) => {
    return (
        <Flex w="100%" justifyContent="center" alignItems="center" flexWrap="wrap">
            <Text w="100%" textAlign="center" fontWeight="black" lineHeight={1.2}  fontSize={{ base: "2xl", lg: "5xl"}}>
                TU PLAN RECOMENDADO ES
            </Text>

            <Text w="100%" textAlign="center" fontStyle="italic" fontWeight="medium" lineHeight={1.2} fontSize="3xl">
                {resultType.planName}
                {resultType.price ? ` POR TAN SOLO ${resultType.price.replace('&nbsp;', ' ')}` : ''}
            </Text>
            {resultType.price !== null ? (
                <Button
                    isLoading={loading}
                    bgGradient="linear(to-r, red.400, orange.400)"
                    _hover={{
                        bgGradient: 'linear(to-r, red.400, orange.400)',
                        boxShadow: 'xl',
                    }}
                    // _hover={{ bg: 'orange.500' }}
                    color="white"
                    size="lg"
                    letterSpacing="1px"
                    minW="150px"
                    fontSize="1.35rem"
                    fontStyle="italic"
                    as={ReachLink}
                    my={8}
                    to={'/acceder'}
                >
                    Regístrate para suscribirte!
                </Button>
            ) : null}
            {resultType.price === null ? (
                <Button
                    bgGradient="linear(to-r, red.400, orange.400)"
                    _hover={{
                        bgGradient: 'linear(to-r, red.400, orange.400)',
                        boxShadow: 'xl',
                    }}
                    color="white"
                    size="lg"
                    letterSpacing="1px"
                    minW="150px"
                    fontSize="1.35rem"
                    fontStyle="italic"
                    my={8}
                    onClick={() => navigate('/contacto')}
                >
                    CONSULTA EL PRECIO
                </Button>
            ) : null}
            <Button
                bgGradient="linear(to-r, blue.500, blue.300)"
                _hover={{
                    bgGradient: 'linear(to-r, blue.500, blue.300)',
                    boxShadow: 'xl',
                }}
                color="white"
                size="lg"
                letterSpacing="1px"
                minW="150px"
                fontSize="1.35rem"
                fontStyle="italic"
                my={8}
                ml={{ base: 0, md: 8}}
                onClick={() => callback({ type: 'changeType', payload: { stepType: 'challenge' } })}
            >
                Volver a empezar
            </Button>
        </Flex>
    );
};

const Second = ({ callback, resultType }) => {
    return (
        <Flex w="100%" justifyContent="center" alignItems="center" flexWrap="wrap">
            <Text w="100%" textAlign="center" fontWeight="black" lineHeight={1.2} fontSize="5xl">
                TU RETO ES
            </Text>
            <Text w="100%" textAlign="center" fontWeight="black" lineHeight={1.2} fontSize="3xl">
                {resultType}
            </Text>
            <Button
                bgGradient="linear(to-r, red.400, orange.400)"
                _hover={{
                    bgGradient: 'linear(to-r, red.400, orange.400)',
                    boxShadow: 'xl',
                }}
                color="white"
                size="lg"
                letterSpacing="1px"
                minW="150px"
                fontSize="1.35rem"
                fontStyle="italic"
                my={8}
                onClick={() =>
                    callback({
                        type: 'changeType',
                        payload: { stepType: 'plan', step: 2 },
                    })
                }
            >
                ¿Qué plan me recomiendas?
            </Button>
        </Flex>
    );
};

const First = ({ callback }) => {
    return (
        <Flex w="100%" justifyContent="center" alignItems="center" flexWrap="wrap">
            <Text w="100%" textAlign="center" fontWeight="black" lineHeight={1.2} fontSize={{ base: "2xl", lg: "5xl"}}>
                ¿QUÉ ANIMAL QUIERES SER?
            </Text>
            <Button
                bgGradient="linear(to-r, red.400, orange.400)"
                _hover={{
                    bgGradient: 'linear(to-r, red.400, orange.400)',
                    boxShadow: 'xl',
                }}
                color="white"
                size="lg"
                my={8}
                letterSpacing="1px"
                minW="150px"
                fontSize="1.35rem"
                fontStyle="italic"
                onClick={() => callback({ type: 'increment' })}
            >
                Empezar
            </Button>
        </Flex>
    );
};

const StepsFlow = ({ question, callback, results }) => {
    return (
        <Flex w="100%" justifyContent="center" alignItems="center" flexWrap="wrap">
            <Text w="100%" textAlign="center" fontWeight="black" lineHeight={1.2}  fontSize={{ base: "2xl", lg: "5xl"}} mb={8}>
                {question.title}
            </Text>
            {question.options.map((option) => {
                return (
                    <Button
                        color="#000"
                        size="lg"
                        letterSpacing="1px"
                        w="100%"
                        fontSize="1.35rem"
                        fontStyle="italic"
                        my={2}
                        onClick={() => {
                            callback({
                                type: 'setResult',
                                payload: { value: results + option.points },
                            });
                            callback({ type: 'increment' });
                        }}
                    >
                        {option.label}
                    </Button>
                );
            })}
        </Flex>
    );
};

export default AutomaticPlan;
