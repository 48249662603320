import { useContext } from "react";
import { useSelector } from "react-redux";
import Library from "../../library";
import { ScreenContext } from "../../pages";
import useAction from "../api/hooks/useAction";

const usePlans = () => {
  const { plans } = useContext(ScreenContext);
  const cart = useSelector((state) => state.cart);
  const refreshCart = useAction("refreshCart");

  const getPlanBySlug = (slug) => {
    return plans.find((plan) => plan.slug === slug);
  };

  const isPlanAlreadyInCart = (slug) => {
    const plan = getPlanBySlug(slug);

    if (!plan) {
      return false;
    }

    const { databaseId } = plan;

    const idx = cart.contents.nodes.findIndex(
      (item) => item.product.node.databaseId === databaseId
    );

    return idx !== -1;
  };

  const addPlanToCart = async (slug) => {
    const plan = getPlanBySlug(slug);
    if (!plan || isPlanAlreadyInCart(slug)) {
      return;
    }

    try {
      await Library.clearCart();
    } catch (error) {}

    await Library.addToCart(plan.databaseId, 1);
    const cart = await Library.getCart();

    await refreshCart({ cart });
  };

  return { addPlanToCart, isPlanAlreadyInCart, getPlanBySlug, plans };
};

export default usePlans;
