import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Default = ({ children, hiddenHero }) => {
    return (
        <div>
            <Header hiddenHero={hiddenHero} />
            { children }
            <Footer />
        </div>
    )
}

export default Default
