import { chakra, Box, Flex, useColorModeValue } from '@chakra-ui/react';
import ViewMoreCard from '../../pages/Home/ViewMoreCard';
import NoSubscriptionCard from '../../pages/Home/NoSubscriptionCard';
import useSubscriptionInfo from "../../hooks/useSubscriptionInfo";
import useAuthentication from "../../redux/hooks/useAuthentication";

const Card = ({ imgUrl, label, h, row }) => {
    const user = useAuthentication()
    const { subscriptionStatus, daysLeft } = useSubscriptionInfo();

    const getComponent = () => {
        if (!user) {
            return <ViewMoreCard imgUrl={imgUrl} row={row} />
        }

        if (subscriptionStatus === 'active') {
            return <ViewMoreCard imgUrl={imgUrl} row={row} hasButton />
        }

        if (subscriptionStatus === 'freeTrial' && daysLeft > 0) {
            return <ViewMoreCard imgUrl={imgUrl} row={row} hasButton />
        }

        return <NoSubscriptionCard />
    }

    return (
        <Flex w="full" alignItems="center" justifyContent="center">
            <Flex direction="column" justifyContent="center" alignItems="center" w="full" mx="auto">
                <Box
                    bg="white"
                    h={h || '500px'}
                    w="full"
                    rounded="lg"
                    shadow="md"
                    bgSize="contain"
                    bgPos="center"
                    bgRepeat="no-repeat"
                    style={{
                        backgroundImage: `url(${imgUrl})`,
                    }}
                ></Box>

                <Box w={{ base: 56, md: 64 }} bg={useColorModeValue('white', 'gray.800')} mt={-10} shadow="lg" rounded="lg" overflow="hidden">
                    <chakra.h3
                        py={2}
                        textAlign="center"
                        fontWeight="black"
                        textTransform="uppercase"
                        color={useColorModeValue('gray.800', 'white')}
                        letterSpacing={1}
                    >
                        {label}
                    </chakra.h3>

                    <Flex alignItems="center" justifyContent="center" py={2} px={3} bg={useColorModeValue('gray.200', 'gray.700')}>
                        {getComponent()}
                    </Flex>
                </Box>
            </Flex>
        </Flex>
    );
};

export default Card;
