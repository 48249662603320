import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Action that updates first name / last name of the user.
 */
class UpdateProfile extends Action {
    constructor() {
        super("updateProfile");
    }

    async action(
        uiState,
        {
            firstName,
            lastName,
        }
    ) { 
        
        return {
            ...uiState,
            user: {
                ...uiState.user,
                lastName: !!lastName ? lastName : uiState.user.lastName,
                firstName: !!firstName ? firstName : uiState.user.firstName,
            },
        };
    }
}

Registry.registerAction(UpdateProfile);