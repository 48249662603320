import { Box, Flex, Stack, Container } from "@chakra-ui/react";
import Default from "../../components/Layout/Default";
import BG from "../../assets/Swan_10.jpg";
import CheckoutFields from "./CheckoutFields";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_live_zhoh6Gjf5EHwGvqIzcg4eRX300l5QlhZI4");

const Checkout = () => {
  return (
    <Elements stripe={stripePromise}>
      <Default hiddenHero>
        <Box
          position={"relative"}
          bgSize={{base: "cover", lg: "65% 120%"}}
          bgRepeat="no-repeat"
          // backgroundAttachment="fixed"
          bgPosition="20% 90%"
          style={{
            backgroundImage: `url(${BG})`,
          }}
          minH="100vh"
        >
          <Container
            justify="flex-end"
            as={Flex}
            maxW={"7xl"}
            py={{ base: 10, sm: 20, lg: 32 }}
          >
            <Stack
              bg="rgba(255,255,255, .75)"
              rounded={"xl"}
              boxShadow="lg"
              p={{ base: 4, sm: 6, md: 8 }}
              spacing={{ base: 8 }}
              w={{ base: "100%", lg: "50%"}}
            >
              <CheckoutFields />
            </Stack>
          </Container>
        </Box>
      </Default>
    </Elements>
  );
};
export default Checkout;
