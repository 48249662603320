import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Text, Button, Stack, Box, Divider } from "@chakra-ui/react";
import { isBillingValid } from "./requiredFields";
import EditLocationScreen from "./EditLocationScreen";
import { states } from "./states";

const LocationScreen = () => {
	const [edit, setEdit] = useState(false);
	const billing = useSelector((state) => state.user?.billing);

	if (isBillingValid(billing) && !edit) {
		return (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				borderRadius="0.5rem"
				margin="0"
				maxW="100%"
				padding={{ base: 0, md: 15 }}
			>
				<Stack
					display="flex"
					flexDir="column"
					padding={{ base: 0, md: 15 }}
					marginTop={{ base: "2rem", md: 0 }}
				>
					<Text fontSize="lg" color="#D75408" fontWeight={"semibold"}>
						Dirección de la calle: <b style={{ color: "red" }}>*</b>
					</Text>
					<Text fontSize="xl" fontWeight={"semibold"}>
						{billing?.address1}
						{billing?.address2 && `, ${billing?.address2}`}
					</Text>
				</Stack>
				<Divider h={{ base: "1rem", md: "0" }} />
				<Stack display="flex" flexDir="column" padding={{ base: 0, md: 15 }}>
					<Text fontSize="lg" color="#D75408" fontWeight={"semibold"}>
						Nombre de empresa: (opcional)
					</Text>
					<Text fontSize="xl" fontWeight={"semibold"}>
						{billing?.company}
					</Text>
				</Stack>
				<Divider h={{ base: "1rem", md: "0" }} />
				<Stack position="relative" padding={{ base: 0, md: 15 }}>
					<Text fontSize="lg" color="#D75408" fontWeight={"semibold"}>
						Provincia: <b style={{ color: "red" }}>*</b>
					</Text>
					<Text fontSize="xl" fontWeight={"semibold"}>
						{states.find((state) => state.value === billing?.state).name}
					</Text>
				</Stack>
				<Divider h={{ base: "1rem", md: "0" }} />
				<Stack position="relative" padding={{ base: 0, md: 15 }}>
					<Text fontSize="lg" color="#D75408" fontWeight={"semibold"}>
						Cuidad: <b style={{ color: "red" }}>*</b>
					</Text>
					<Text fontSize="xl" fontWeight={"semibold"}>
						{billing?.city}
					</Text>
				</Stack>
				<Divider h={{ base: "1rem", md: "0" }} />
				<Stack display="flex" flexDir="column" padding={{ base: 0, md: 15 }}>
					<Text fontSize="lg" color="#D75408" fontWeight={"semibold"}>
						Código Postal: <b style={{ color: "red" }}>*</b>
					</Text>
					<Text fontSize="xl" fontWeight={"semibold"}>
						{billing?.postcode}
					</Text>
				</Stack>
				<Divider h={{ base: "1rem", md: "0" }} />
				<Stack />
				<Stack display="flex" flexDir="column" padding={{ base: 0, md: 15 }}>
					<Text fontSize="lg" color="#D75408" fontWeight={"semibold"}>
						Teléfono: (opcional)
					</Text>
					<Text fontSize="xl" fontWeight={"semibold"}>
						{billing?.phone}
					</Text>
				</Stack>
				<Divider h={{ base: "1rem", md: "0" }} />
				<Button
					rounded="md"
					size={{ base: "sm", md: "sm" }}
					fontWeight="600"
					color="white"
					backgroundColor="orange.300"
					maxW="10rem"
					mt="1.5rem"
					padding="15px"
					fontSize="1rem"
					onClick={() => setEdit(!edit)}
					bgGradient="linear(to-r, red.400, orange.400)"
				>
					EDITAR
				</Button>
			</Box>
		);
	}

	return <EditLocationScreen setEdit={setEdit} edit={edit} billing={billing} />;
};

export default LocationScreen;
