import { navigate } from "@reach/router";
import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Action that logs out the user
 */
class LogoutAction extends Action {
  constructor() {
    super("logout");
  }

  async action(state) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    navigate("/");
    return { ...state, user: null };
  }
}

Registry.registerAction(LogoutAction);
