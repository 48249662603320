import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Action refreshes the user cart
 */
class RefreshCartAction extends Action {
  constructor() {
    super("refreshCart");
  }

  async action(state, { cart }) {
    return { ...state, cart };
  }
}

Registry.registerAction(RefreshCartAction);
