import { Text, Box, Divider } from "@chakra-ui/react";
import { useSelector } from "react-redux";
//{subtotal.replace("&nbsp;", " ")}
const Order = () => {
	const {
		contents: { nodes: cartItems },
		totalTax,
		total,
		discountTotal,
	} = useSelector((state) => state.cart);

	return (
		<Box mt="1.5rem">
			{cartItems.map((cartItem) => (
				<Box display="flex" justifyContent="space-between">
					<Text color="gray.800" fontWeight="semibold">
						{cartItem.product.node.name}
					</Text>
					<Text color="gray.800" fontWeight="semibold">
						{cartItem.subtotal.replace("&nbsp;", " ")}
					</Text>
				</Box>
			))}
			<Box>
				<Box display="flex" justifyContent="space-between" mt="1rem">
					<Text color="gray.700">Descuento Cupón</Text>
					<Text fontWeight="medium">
						-{discountTotal.replace("&nbsp;", " ")}
					</Text>
				</Box>
				<Box display="flex" justifyContent="space-between" mt="1rem">
					<Text color="gray.700">IVA </Text>
					<Text fontWeight="light">{totalTax.replace("&nbsp;", " ")}</Text>
				</Box>
				<Divider h="1.5rem" />
				<Box display="flex" justifyContent="space-between" mt="1rem">
					<Text color="gray.700">Total </Text>
					<Text fontWeight="hairline">{total.replace("&nbsp;", " ")}</Text>
				</Box>
			</Box>
		</Box>
	);
};

export default Order;
