import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Action that logs in the user
 */
class UpdateBilling extends Action {
    constructor() {
        super("updateBilling");
    }

    async action(
        uiState,
        {
            address1,
            address2,
            city,
            postcode,
            company,
            phone,
            state
        }
    ) { 
        
        return {
            ...uiState,
            user: {
                ...uiState.user,
                billing: {
                    ...uiState.user.billing,
                    address1,
                    address2,
                    city,
                    postcode,
                    company,
                    phone,
                    state
                }
            },
        };
    }
}

Registry.registerAction(UpdateBilling);