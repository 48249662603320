import Library from "../../library";
import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Action that refresh the auth token of the user
 */
class RefreshAuthTokenAction extends Action {
  constructor() {
    super("refreshAuthToken");
  }

  async action(state) {
    const { user } = state;

    // If there's a session stored, refresh the auth token
    if (user) {
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const {
          refreshJwtAuthToken: { authToken },
        } = await Library.refreshAuthToken(refreshToken);
        return { ...state, user: { ...user, authToken } };
      } catch (error) {
        if (process.env.REACT_APP_ENV === "dev") {
          console.log("Using react env, skipping refresh auth token step");
        } else {
          return {
            ...state,
            user: null,
          };
        }
      }
    }

    return { ...state };
  }
}

Registry.registerAction(RefreshAuthTokenAction);
