import React, { useState } from "react";
import {
	Box,
	Stack,
	Input,
	Button,
	useToast,
	Text,
	Heading,
} from "@chakra-ui/react";
import handleChange from "../../library/api/utils/handleChange";
import Library from "../../library";
import useAction from "../../redux/api/hooks/useAction";
import { FREE_TRIAL_DAYS } from "../../constants";
import { navigate } from "@reach/router";
import { useSelector } from "react-redux";

const removeHtml = (str) => {
	return str.replace(/<(?:.|\n)*?>/gm, "");
};

const SignUp = () => {
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [email, setEmail] = useState("");
	const [passwordRepeat, setPasswordRepeat] = useState("");
	const afterLogin = useSelector((state) => state.afterLoginNavigation);
	const setAfterLoginNavigation = useAction("setAfterLoginNavigation");
	const toast = useToast();

	const doSaveUser = useAction("login");

	const doRegister = async () => {
		try {
			setLoading(true);

			if (password !== passwordRepeat) {
				toast({
					title: "Error",
					description:
						"Asegúrate de que has introducido las contraseñas correctamente.",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
				return;
			}

			const {
				registerCustomer: {
					customer: { id, jwtAuthToken, jwtRefreshToken, billing },
					viewer: {
						memberships,
						name,
						capabilities,
						registeredDate,
						firstName,
						lastName,
                        databaseId
					},
				},
			} = await Library.doRegister(username, password, email);

			await doSaveUser({
				id,
				name,
				email,
				authToken: jwtAuthToken,
				refreshToken: jwtRefreshToken,
				memberships,
				capabilities,
				registeredDate,
                firstName,
                lastName,
                billing,
                databaseId
			});

			if (afterLogin && afterLogin !== "") {
				navigate(afterLogin);
				await setAfterLoginNavigation({ path: "" });
				return;
			}

			navigate("/");
		} catch (error) {
			toast({
				title: "Error",
				description: `${removeHtml(error.message)}`,
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Box as={"form"}>
				<Stack spacing={4}>
					<Heading
						color={"gray.800"}
						lineHeight={1.1}
						fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
						textAlign="center"
						mb="1rem"
					>
						Comienza tu prueba gratis de {FREE_TRIAL_DAYS} días!
					</Heading>
					<Input
						onChange={handleChange((val) => setEmail(val.toLowerCase()))}
						placeholder="Correo Electrónico"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) => setUsername(val))}
						placeholder="Nombre de usuario"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) => setPassword(val))}
						placeholder="Contraseña"
						type="password"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
					<Input
						onChange={handleChange((val) => setPasswordRepeat(val))}
						placeholder="Repite la contraseña"
						type="password"
						bg={"gray.100"}
						border={0}
						color={"gray.500"}
						_placeholder={{
							color: "gray.500",
						}}
					/>
				</Stack>
				{password && passwordRepeat && password !== passwordRepeat && (
					<Text
						color="red"
						marginTop="1rem"
						fontSize="1rem"
						fontWeight="bold"
						textAlign="center"
					>
						Contraseñas no concuerdan
					</Text>
				)}
				<Button
					onClick={doRegister}
					disabled={
						!username ||
						username === "" ||
						!password ||
						password === "" ||
						(password && password !== passwordRepeat)
					}
					fontFamily={"heading"}
					mt={8}
					w={"full"}
					bgGradient="linear(to-r, red.400,orange.400)"
					color={"white"}
					isLoading={loading}
					_hover={{
						bgGradient: "linear(to-r, red.400,orange.400)",
						boxShadow: "xl",
					}}
				>
					Regístrate
				</Button>
			</Box>
		</>
	);
};

export default SignUp;
