import { useDispatch } from "react-redux";
import Registry from "../Registry";

const dispatchThunk = (theThunk) => async (dispatch) => {
  await dispatch(theThunk);
};

/**
 * @description Method that returns an action executor
 * @param {string} actionName The name of the action to be executed.
 */
const useAction = (actionName) => {
  const dispatch = useDispatch();
  const action = Registry.getAction(actionName);

  if (!action) {
    throw new Error(`Action ${actionName} not registered!`);
  }

  return async (payload) => {
    await dispatch(dispatchThunk(action.launch(payload)));
  };
};

export default useAction;
