import React from "react";
import {
	Button,
	Flex,
	Container,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Text,
} from "@chakra-ui/react";
import Default from "../../components/Layout/Default";
import Screen from "./Screen/Screen";
import ProfileScreen from "./Screen/ProfileScreen";
import SubscriptionScreen from "./Screen/SubscriptionScreen";
import LocationScreen from "./Screen/LocationScreen";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useAction from "../../redux/api/hooks/useAction";
import PaymentContainer from "./Screen/PaymentContainer";
import { ImProfile } from "react-icons/im";
import { AiTwotoneShopping } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Divider } from "@chakra-ui/react";
import FreeTrialBar from "../Authentication/FreeTrialBar/FreeTrialBar";
import useSubscriptionInfo from "../../hooks/useSubscriptionInfo";
const stripePromise = loadStripe("pk_test_34CHxZsr3giDlJQRDNV7kch7009B2xkeHu");

const Profile = () => {
	const doCloseSession = useAction("logout");
    const { subscriptionStatus, daysLeft } = useSubscriptionInfo();

	return (
		<Elements stripe={stripePromise}>
			<Default hiddenHero>
				<FreeTrialBar
					daysLeft={daysLeft}
					subscriptionStatus={subscriptionStatus}
                    needsButton={true}
				/>
				<Container
					as={Flex}
					height={{ base: "100%", md: "135vh" }}
					width="100%"
					maxW="100%"
					paddingInline="0"
				>
					<Tabs
						display="flex"
						flexDir={{ base: "column", md: "row" }}
						flex="1"
						isFitted
						variant="unstyled"
						colorScheme="green"
						orientation={"horizontal"}
						height="100%"
						width="100%"
						isLazy
					>
						<TabList
							as={Flex}
							flexDir={{ base: "row", md: "column" }}
							maxWidth="100%"
							height="100%"
							backgroundColor="gray.200"
							width={{ base: "100%", md: "30%" }}
							flexWrap={{ base: "wrap", lg: "nowrap" }}
						>
							<Text
								color="#D75408"
								fontWeight="semibold"
								fontFamily="sans-serif"
								fontSize="2.3rem"
								textAlign="center"
								alignSelf="center"
								pt="2rem"
								pb="2rem"
								w="100%"
							>
								Mi Cuenta
							</Text>
							<Divider />
							<Tab
								justifyContent="start"
								paddingLeft="2.5rem"
								rounded="md"
								maxH="5rem"
								margin="0 auto"
								width="100%"
								outline="none"
								borderRadius="0"
								fontSize="1.2rem"
								as={Button}
								leftIcon={<ImProfile size={20} />}
								_selected={{
									color: "orange.400",
								}}
							>
								Perfil
							</Tab>
							<Divider />
							<Tab
								justifyContent="start"
								as={Button}
								paddingLeft="2.5rem"
								leftIcon={<AiTwotoneShopping size={24} />}
								rounded="md"
								maxH="5rem"
								width="100%"
								fontSize="1.2rem"
								outline="none"
								borderRadius="0"
								margin="0 auto"
								_selected={{
									color: "orange.400",
								}}
							>
								Suscripciones
							</Tab>
							<Divider />
							<Tab
								justifyContent="start"
								as={Button}
								paddingLeft="2.5rem"
								leftIcon={<GoLocation size={20} />}
								rounded="md"
								maxH="5rem"
								width="100%"
								fontSize="1.2rem"
								margin="0 auto"
								outline="none"
								borderRadius="0"
								_selected={{
									color: "orange.400",
								}}
							>
								Dirección
							</Tab>
							<Divider />
							<Tab
								justifyContent="start"
								as={Button}
								leftIcon={<MdPayment size={24} />}
								rounded="md"
								maxH="5rem"
								paddingLeft="2.5rem"
								margin="0 auto"
								fontSize="1.2rem"
								width="100%"
								outline="none"
								_disabled={{
									color: "white",
									bgGradient: "linear(to-r, red.400, orange.400)",
								}}
								borderRadius="0"
								_selected={{
									color: "orange.400",
								}}
							>
								Método de Pago
							</Tab>
							<Divider />
							<Button
								rounded="xl"
								maxh={{ base: "3rem", md: "4rem" }}
								height={{ base: "3rem", md: "4rem" }}
								margin="0 auto"
								fontSize={{ base: "1rem", md: "1.2rem" }}
								bgColor="transparent"
								leftIcon={<RiLogoutBoxLine size={20} />}
								outline="none"
								borderColor="orange.400"
								borderWidth="2px"
								mt="5rem"
								mb="1.5rem"
								onClick={() => doCloseSession()}
							>
								CERRAR SESIÓN
							</Button>
						</TabList>
						<TabPanels marginRight="1rem" height="100%" display="flex">
							<TabPanel px={{ base: 0, md: "inherit" }} width="100%">
								<Screen title="Perfil" content={<ProfileScreen />} />
							</TabPanel>
							<TabPanel
								px={{ base: 0, md: "inherit" }}
								width="100%"
								overflowY="scroll"
							>
								<Screen
									title="Suscripciones"
									content={<SubscriptionScreen />}
								/>
							</TabPanel>
							<TabPanel px={{ base: 0, md: "inherit" }} width="100%">
								<Screen
									title="Dirección de Facturación"
									content={<LocationScreen />}
								/>
							</TabPanel>
							<TabPanel px={{ base: 0, md: "inherit" }} width="100%">
								<Screen title="Método de Pago" content={<PaymentContainer />} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Container>
			</Default>
		</Elements>
	);
};
export default Profile;
