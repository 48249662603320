import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Flex,
    // Icon,
} from '@chakra-ui/react';
import FreeTrialBar from '../Authentication/FreeTrialBar/FreeTrialBar';
import Swan from '../../assets/Swan_09.jpg';
import { navigate } from "@reach/router";
import React from 'react';

export default function NoSubscriptionCard() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = React.useRef();

    const navigateToPlans = () => {
        navigate(`/planes`);
    };

    return (
        <>
            <Button
                fontSize="xs"
                fontWeight="600"
                size="xs"
                px={8}
                py={1}
                color={'white'}
                rounded={'lg'}
                bgGradient="linear(to-r, blue.500, blue.300)"
                _hover={{
                    bgGradient: 'linear(to-r, blue.500, blue.300)',
                    boxShadow: 'xl',
                }}
                onClick={onOpen}
            >
                Ver reto
            </Button>
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minW={{ "md": "xl" }}>
                    <ModalCloseButton />
                    <FreeTrialBar subscriptionStatus="expired" padding={{ base: '1rem', sm: '1.5rem' }} />
                    <ModalBody>
                        <Flex
                            minH={{ base: "22rem", md: "28rem" }}
                            py={{ base: 4, md: 4, lg: 4 }}
                            px={{ base: 4, md: 16, xl: 16 }}
                            backgroundImage={`url(${Swan})`}
                            bgSize={{ base: "70%", md: "70%" }}
                            bgPosition={{ base: "120% 100%", md: "110% 70%" }}
                            bgRepeat="no-repeat"
                            bgColor="#fff"
                        >
                            <Button
                                onClick={navigateToPlans}
                                fontWeight="600"
                                padding={{ base: "0.5rem 1rem", md: "1.5rem 2rem" }}
                                color="white"
                                mt={{ base: '2.5rem', md: '5rem' }}
                                bgGradient="linear(to-r, red.400, orange.400)"
                                borderRadius="0.5rem"
                                fontSize="1.4rem"
                            >
                                SUSCRÍBETE
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
