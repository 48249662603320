/**
 * Registry class
 */
class AGRegistry {
  actions = {};

  /**
   * @description Method to register an action
   * @param {Action} Action Action to be registered
   */
  registerAction(Action) {
    const action = new Action();
    this.actions[action.actionName] = action;
  }

  /**
   * @description Method to get all the actions
   */
  getActions() {
    return this.actions;
  }

  /**
   * @description Method to get the action by its name
   * @param {string} actionName
   */
  getAction(actionName) {
    return this.actions[actionName];
  }
}

const Registry = new AGRegistry();

export default Registry;
