import React, { useState } from "react";
import Cards from "react-credit-cards";
import { Input, SimpleGrid, Button, Box, Text, Spinner } from "@chakra-ui/react";

import "react-credit-cards/es/styles-compiled.css";

const onlyNumbers = (value) => {
	return value === value.replace(/[^\d]/g, "");
};

const PaymentMethod = ({
	issuer,
	cardValues,
	setCardValues,
	currentCard,
	saveCard,
	loading,
	preview = false,
}) => {
	const [editCard, setEditCard] = useState(false);

	if (loading) return (
		<Box
			id="PaymentForm"
			padding={{ base: "0", md: "1.5rem" }}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			width="xs"
			height="xs"
		>
			<Spinner
				thickness="4px"
				speed="0.65s"
				emptyColor="gray.200"
				color="blue.500"
				size="xl"
			/>
		</Box>
	)

	const handleInputFocus = (e) => {
		setCardValues({ ...cardValues, focus: e.target.name });
	};

	const handleCardNumber = (e) => {
		const { value } = e.target;
		if (value.length <= 16 && onlyNumbers(value)) {
			setCardValues({ ...cardValues, number: value });
		}
	};

	const handleExpirationDate = (e) => {
		const { value } = e.target;
		if (value.length <= 4 && onlyNumbers(value)) {
			setCardValues({ ...cardValues, expiry: value });
		}
	};

	const handleCvc = (e) => {
		const { value } = e.target;
		if (value.length <= 3 && onlyNumbers(value)) {
			setCardValues({ ...cardValues, cvc: value });
		}
	};

	const handleEditCard = () => {
		setEditCard(!editCard);
		setCardValues({ cvc: "", expiry: "", focus: "", name: " ", number: "" });
	};

	const handleCancelCard = () => {
		setEditCard(false);
		setCardValues({ ...currentCard });
	};

	const handleSaveCard = () => {
		saveCard(cardValues);
		setEditCard(false);
	};

	const disableSaveCard =
		cardValues.number?.length < 16 ||
		cardValues.expiry?.length < 4 ||
		cardValues.cvc?.length < 3;

	return (
		<Box
			id="PaymentForm"
			padding={{ base: "0", md: "1.5rem" }}
			display="flex"
			flexDirection="column"
			justifyContent="center"
		>
			{currentCard && <Text fontWeight="semibold" textAlign="center" color='blackAlpha.800' marginBottom="1rem">Metodo de pago predefinido</Text>}
			<Cards
				locale={{ valid: 'Valido hasta' }}
				issuer={!cardValues ? issuer : null}
				preview={preview}
				cvc={cardValues.cvc || currentCard?.cvc || ""}
				expiry={cardValues.expiry || currentCard?.expiry || ""}
				focused={cardValues.focus}
				name={cardValues.name || " "}
				number={cardValues.number || currentCard?.number || ""}
			/>
			{editCard && (
				<SimpleGrid>
					<Input
						m="2rem 0rem 2rem"
						type="tel"
						name="number"
						color="black"
						bgColor="whiteAlpha.800"
						borderColor="blackAlpha.400"
						placeholder="Número de tarjeta"
						_placeholder={{
							color: "gray.500",
							fontSize: { base: "xs", md: "sm" },
						}}
						value={cardValues.number}
						onChange={handleCardNumber}
						onFocus={handleInputFocus}
					/>
					<div style={{ display: "flex" }}>
						<Input
							width="11rem"
							type="tel"
							name="expiry"
							color="black"
							bgColor="whiteAlpha.800"
							borderColor="blackAlpha.400"
							value={cardValues.expiry}
							placeholder="Fecha de Expiración"
							onChange={handleExpirationDate}
							onFocus={handleInputFocus}
							_placeholder={{
								color: "gray.500",
								fontSize: { base: "xs", md: "sm" },
							}}
						/>
						<Input
							ml="auto"
							width="5rem"
							type="tel"
							name="cvc"
							color="black"
							bgColor="whiteAlpha.800"
							borderColor="blackAlpha.400"
							placeholder="CVC"
							value={cardValues.cvc}
							onChange={handleCvc}
							onFocus={handleInputFocus}
							_placeholder={{
								color: "gray.500",
								fontSize: { base: "xs", md: "sm" },
							}}
							mb="1.5rem"
						/>
					</div>
				</SimpleGrid>
			)}

			{!editCard && (
				<Button
					mt="1rem"
					onClick={handleEditCard}
					rounded="md"
					bgGradient="linear(to-r, red.400, orange.400)"
					_hover={{
						bgGradient: "linear(to-r, red.400, orange.400)",
						boxShadow: "xl",
					}}
					color="white"
				>
					Editar
				</Button>
			)}
			{editCard && (
				<Box
					display="flex"
					width="100%"
					justifyContent="space-between"
					mt="0.5rem"
				>
					<Button
						bgColor="green.400"
						color="white"
						onClick={handleSaveCard}
						disabled={disableSaveCard}
					>
						GUARDAR
					</Button>
					<Button
						fontWeight="600"
						color="white"
						bgColor="red.400"
						onClick={handleCancelCard}
					>
						CANCELAR
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default PaymentMethod;
