import React, { createContext, useEffect, useState } from 'react';
import { Router, Location, navigate } from '@reach/router';
import { ApolloProvider } from '@apollo/client';
import Library from '../library';
import Home from './Home';
import '../redux/actions';
import posed, { PoseGroup } from 'react-pose';

import useAction from '../redux/api/hooks/useAction';
// import Loading from '../components/Loading';
import Plans from './Plans';
import Authentication from './Authentication';
import Contact from './Contact';
import useAuthentication from '../redux/hooks/useAuthentication';
import ChallengesPlaylist from './ChallengesPlaylist';
import Checkout from './Checkout';
import OrderReceived from './Order/OrderReceived';
import View from './View';
import './styles.css';
import Profile from './Profile';
import { Box } from '@chakra-ui/layout';
import { Helmet } from 'react-helmet';
import Cart from './Cart';
import useSubscriptionStatus from '../hooks/useSubscriptionInfo';

export const ScreenContext = createContext({
    cart: [],
    plans: [],
});

const RouteContainer = posed.div({
    enter: { opacity: 1, delay: 300, beforeChildren: 300 },
    exit: { opacity: 0 },
});

const PosedRouter = ({ children }) => (
    <Location>
        {({ location }) => (
            <PoseGroup>
                <RouteContainer key={location.key}>
                    <Router as={Box} overflowX="hidden" w="100vw" location={location}>
                        {children}
                    </Router>
                </RouteContainer>
            </PoseGroup>
        )}
    </Location>
);

const Pages = () => {
    const [client, setClient] = useState([]);
    const [views, setViews] = useState([]);
    const [settings, setSettings] = useState({});
    // const [isLoading, setLoading] = useState(true);

    const [plans, setPlans] = useState([]);
    const refreshUser = useAction('refreshUser');
    const refreshCart = useAction('refreshCart');
    const logout = useAction('logout');

    const user = useAuthentication();
    const { subscriptionStatus } = useSubscriptionStatus();
    const hiddenChangelles = subscriptionStatus !== 'active' && subscriptionStatus !== 'freeTrial';
    const initialize = async () => {
        // ? Refresh the auth token
        // TODO: Fix this :D
        // await refreshAuthToken();

        try {
            // ? Then refresh the user
            await refreshUser();
        } catch (err) {
            navigate('/');
            await logout();
        }
        // const menu = await Library.getMenu();
        const cart = await Library.getCart();
        const loadedPlans = await Library.getPlans();
        const settings = await Library.getSettings();
        const views = await Library.getMenu();
        await refreshCart({ cart });
        setViews(views);
        setPlans(loadedPlans);
        setClient({ ...client });
        // setLoading(false);
        setSettings(settings);
    };

    useEffect(
        () => initialize(),
        // eslint-disable-next-line
        []
    );

    // if (isLoading) return <Loading />;

    return (
        <ScreenContext.Provider value={{ plans }}>
            <ApolloProvider client={client}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <link rel="icon" href="https://api.entrenaconswan.com/wp-content/uploads/2021/05/cropped-FAVICON-ECS-32x32.png" sizes="32x32"></link>
                    <title>{settings && settings.generalSettingsTitle}</title>
                    <meta name="description" content={settings && settings.generalSettingsDescription} />
                </Helmet>

                <PosedRouter onLoad={() => window.scrollTo(0, 0)}>
                    <Home path="/" />
                    <Plans path="/planes" />
                    {!user ? <Authentication path="/acceder" /> : null}
                    {!user ? <Authentication path="/acceder/:tab" /> : null}
                    <Contact path="/contacto" />
                    {user && !hiddenChangelles ? <ChallengesPlaylist path="/retos/:name" /> : null}
                    {user && !hiddenChangelles ? <ChallengesPlaylist path="/retos" /> : null}
                    {/* <ChallengesPlaylist path="/retos" /> */}
                    {user ? <OrderReceived path="/pedido-realizado" /> : null}
                    {!user ? null : <Checkout path="/finalizar-compra" />}
                    {user ? <Profile path="/perfil/" /> : null}
                    {user ? <Cart path="/carrito/" /> : null}
                    {views.map(view => (
                        <View key={view.path} path={view.path} />
                    ))}
                </PosedRouter>
            </ApolloProvider>
        </ScreenContext.Provider>
    );
};

export default Pages;
