

export const requiredDirectionFields = [
    'address1',
    'city',
    'postcode',
    'state'
]

export const isBillingValid = (billing) => {
    if (billing) {
        return requiredDirectionFields.every((field) => {
            return !!billing[field]
        })
    }
    
    return false
}
