import React, { useState } from "react";
import { Box, Button, Divider } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import useAction from "../../../redux/api/hooks/useAction";
import { isBillingValid } from "./requiredFields";
import Library from "../../../library";
import EditLocationInputs from "./EditLocationInputs";
import _ from "lodash";

const EditLocationScreen = ({ billing, setEdit, edit }) => {
	const updateBilling = useAction("updateBilling");
	const user = useSelector((state) => state.user);
	const [loading, setLoading] = useState(false);

	const [formData, setFormData] = useState({
		...billing,
	});

	const handleSubmit = async () => {
		if (_.isEqual(formData, billing)) {
			setEdit(false);
			return;
		}

		setLoading(true);
		await Library.updateCustomer({
			input: {
				id: user?.id,
				billing: { ...formData, __typename: undefined },
			},
		});

		await updateBilling({ ...formData });

		setLoading(false);
		setEdit(false);
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="flex-start"
			borderRadius="0.5rem"
			margin="0"
			maxW="100%"
			padding={{ base: 0, md: 15 }}
		>
			<EditLocationInputs formData={formData} setFormData={setFormData} />
			<Divider />
			<Box as={"flex"} flexDir="row" mt="1.5rem" padding="15px">
				<Button
					disabled={!isBillingValid(formData)}
					fontWeight="600"
					color="white"
					bgColor="green.500"
					borderRadius="0.5rem"
					w={{ base: "7rem", md: "10rem" }}
					h="3.5rem"
					fontSize="1rem"
					onClick={handleSubmit}
					isLoading={loading}
				>
					ACEPTAR
				</Button>
				<Button
					marginLeft="1rem"
					fontWeight="600"
					color="white"
					bgColor="red.400"
					borderRadius="0.5rem"
					w={{ base: "7rem", md: "10rem" }}
					h="3.5rem"
					fontSize="1rem"
					onClick={() => setEdit(!edit)}
				>
					CANCELAR
				</Button>
			</Box>
		</Box>
	);
};

export default EditLocationScreen;
