import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { FREE_TRIAL_DAYS } from "../constants";

const useSubscriptionStatus = () => {
	const user = useSelector(state => state.user);
	if (!user) {
		return {
			subscriptionStatus: "noUser",
			daysLeft: -1
		};
	}

	const freeTrial = user?.isFreeTrial;
	const memberships = user?.memberships
	const registeredDate = user?.registeredDate


	if (!!memberships.length && memberships.some(m => m.status === "active")) {
		return {
			subscriptionStatus: "active",
			daysLeft: 1,
		};
	}

	if (freeTrial && !memberships.length) {
		const registered = dayjs(registeredDate);
		const today = dayjs(new Date());
		const difference = today.diff(registered, "day");
		const daysLeft = FREE_TRIAL_DAYS - difference

		if (daysLeft > 0) {
			return {
				subscriptionStatus: "freeTrial",
				daysLeft
			};
		}
		return {
			subscriptionStatus: "expired",
			daysLeft: 0,
		};
	}

	if (!freeTrial && !memberships.length) {
		return {
			subscriptionStatus: "expired",
			daysLeft: 0,
		};
	}

	return {
		subscriptionStatus: "expired",
		daysLeft: 0,
	};
};

export default useSubscriptionStatus;
