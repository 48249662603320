import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import Library from "../../../library";

const SendPasswordResetLinkModal = ({ isOpened, onClose, defaultEmail }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(defaultEmail);
  const toast = useToast();

  const handleSendPasswordLink = async () => {
    setLoading(true);

    try {
      await Library.sendPasswordResetLink(email);
      toast({
        title: "¡Exito!",
        description:
          "Te hemos enviado un enlace a tu correo para restaurar tu contraseña.",
        status: "success",
      });

      onClose();
    } catch (err) {
      return toast({
        title: "Error",
        description:
          "Ha ocurrido un error inesperado al intentar enviar el enlace.",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpened} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Restaurar contraseña</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            Ingresa tu correo electrónico para recibir un enlace en tu casilla
            de correos para restablecer tu contraseña.
          </Text>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            mb={3}
            type="email"
            placeholder="Correo electrónico"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onClose();
            }}
            mr={2}
            w={"full"}
            bgGradient="linear(to-r, red.400, orange.400)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, red.400, orange.400)",
              boxShadow: "xl",
            }}
          >
            Cerrar
          </Button>
          <Button
            isLoading={loading}
            onClick={handleSendPasswordLink}
            loadingText="Cargando..."
            w={"full"}
            bgGradient="linear(to-r, red.400, orange.400)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, red.400, orange.400)",
              boxShadow: "xl",
            }}
          >
            Restaurar contraseña
          </Button>{" "}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SendPasswordResetLinkModal;
