import { useSelector } from "react-redux";

/**
 * @description Hook that will return null if the user is not authenticated and the user itself if authenticated.
 */
const useAuthentication = () => {
  const user = useSelector((state) => state.user);
  return user || null;
};

export default useAuthentication;
