import { gql } from "@apollo/client";
import _ from "lodash";

export const CREATE_CARD = ({
	cvc,
	cardMonth,
	cardNumber,
	cardYear,
	userId,
}) => gql`
  mutation CreateCard {
    createSource(
        input: {
            card_cvc: "${cvc}",
            card_month:  "${cardMonth}",
            card_number:  "${cardNumber}",
            card_year:  "${cardYear}", 
            clientMutationId: "${_.uniqueId("auth_")}",
            user: "${userId}"
        }
    ) {
        source
        last4
        exp_year
        exp_month
        brand
    }
  }
`;

export const RETRIEVE_CARD = (userId) => gql`
  mutation RETRIEVE_CARD {
    retrieveSource(
        input: {
            clientMutationId: "${_.uniqueId("auth_")}",
            user: "${userId}"
        }
    ) {
        brand
        source
        exp_month
        exp_year
        last4
        stripe_customer_id
    }
  }
`;
