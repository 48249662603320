import { Box, Flex, Stack, Container } from '@chakra-ui/react';
import Default from '../../components/Layout/Default';
import BG from '../../assets/Swan_09.jpg';
import ContactFields from './ContactFields';

const Contact = () => {
    return (
        <Default hiddenHero>
            <Box
                position={'relative'}
                bgSize="60%"
                bgPosition={{ base: '0% 40%', md: 'bottom center', lg: '0% 40%' }}
                bgRepeat="no-repeat"
                bgImage={{ md: `url(${BG})` }}
                h={{ base: 'auto', md: '100vh', lg: 'auto' }}
            >
                <Container justify={{ base: 'center', lg: 'flex-end' }} as={Flex} maxW={'7xl'} py={{ base: 4, lg: 24 }}>
                    <Stack bg="rgba(255,255,255, .5)" rounded={'xl'} boxShadow="lg" p={{ base: 4, sm: 6, md: 8 }} spacing={{ base: 8 }} w="lg">
                        <ContactFields />
                    </Stack>
                </Container>
            </Box>
        </Default>
    );
};
export default Contact;
