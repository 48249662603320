import Action from "../api/Action";
import Registry from "../api/Registry";

/**
 * Saves the recently created order inside the local storage
 */
class SetAfterLoginNavigationAction extends Action {
  constructor() {
    super("setAfterLoginNavigation");
  }

  async action(state, { path }) {
    return { ...state, afterLoginNavigation: path };
  }
}

Registry.registerAction(SetAfterLoginNavigationAction);
