import Action from "../api/Action";
import Registry from "../api/Registry";
import dayjs from "dayjs";
import { FREE_TRIAL_DAYS } from "../../constants";

/**
 * Action that logs in the user
 */
class LoginAction extends Action {
	constructor() {
		super("login");
	}

	async action(
		state,
		{
			id,
			name,
			email,
			authToken,
			refreshToken,
			capabilities,
			memberships,
			registeredDate,
            firstName,
            lastName,
            billing,
            databaseId
		}
	) {
		localStorage.setItem("authToken", authToken);
		localStorage.setItem("refreshToken", refreshToken);

		const registered = dayjs(registeredDate);
		const today = dayjs(new Date());
        const difference = today.diff(registered, 'day')
        const isFreeTrial = difference <= FREE_TRIAL_DAYS && !memberships.length;

		return {
			...state,
			user: {
				id,
				name,
				email,
				authToken,
				refreshToken,
				capabilities,
				memberships,
                isFreeTrial,
                registeredDate,
                firstName,
                lastName,
                billing,
                databaseId
			},
		};
	}
}

Registry.registerAction(LoginAction);
