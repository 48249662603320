import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    useDisclosure,
    Box,
    Button,
    Flex,
    useColorModeValue,
    Image,
    // Icon,
    chakra,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { Link as ReachLink } from '@reach/router';
import React from 'react';

export default function ViewMoreCard({ row, imgUrl, hasButton }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const finalRef = React.useRef();

    return (
        <>
            <Button
                fontSize="xs"
                fontWeight="600"
                size="xs"
                px={8}
                py={1}
                color={'white'}
                rounded={'lg'}
                bgGradient="linear(to-r, blue.500, blue.300)"
                _hover={{
                    bgGradient: 'linear(to-r, blue.500, blue.300)',
                    boxShadow: 'xl',
                }}
                onClick={onOpen}
            >
                Ver reto
            </Button>
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{row.animal}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex p={50} w="full" alignItems="center" justifyContent="center">
                            <Box w="sm" mx="auto" bg={useColorModeValue('white', 'gray.800')} shadow="lg" rounded="lg" overflow="hidden">
                                <Image w="full" h={56} fit="contain" objectPosition="center" src={imgUrl} alt="avatar" />

                                <Flex flexWrap="wrap" justifyContent="space-between" alignItems="center" px={6} py={3} bg="gray.900">
                                    <chakra.h1 width="100%" fontSize="xl" fontWeight="bold" color="white">
                                        Para Quien
                                    </chakra.h1>
                                    <chakra.p width="100%" color="white">{row.quien}</chakra.p>
                                </Flex>

                                <Box py={4} px={6}>
                                    <chakra.h1 mx={3} color={useColorModeValue('gray.700', 'gray.400')} fontWeight="bold" fontSize="lg">
                                        Actividades
                                    </chakra.h1>

                                    <chakra.p py={2} color={useColorModeValue('gray.700', 'gray.400')}>
                                        {row.actividades}
                                    </chakra.p>

                                    <Flex alignItems="center" mt={4} color={useColorModeValue('gray.700', 'gray.200')}>
                                        <Stars count={row.fuerza} />
                                        <chakra.h1 px={2} fontSize="sm" fontWeight="bold">
                                            Fuerza
                                        </chakra.h1>
                                    </Flex>

                                    <Flex alignItems="center" mt={4} color={useColorModeValue('gray.700', 'gray.200')}>
                                        <Stars count={row.resistencia} />
                                        <chakra.h1 px={2} fontSize="sm" fontWeight="bold">
                                            Resistencia
                                        </chakra.h1>
                                    </Flex>

                                    <Flex alignItems="center" mt={4} color={useColorModeValue('gray.700', 'gray.200')}>
                                        <Stars count={row.potencia} />
                                        <chakra.h1 px={2} fontSize="sm" fontWeight="bold">
                                            Potencia
                                        </chakra.h1>
                                    </Flex>
                                    <Flex alignItems="center" mt={4} color={useColorModeValue('gray.700', 'gray.200')}>
                                        <Stars count={row.equilibrio} />
                                        <chakra.h1 px={2} fontSize="sm" fontWeight="bold">
                                            Equilibrio
                                        </chakra.h1>
                                    </Flex>
                                </Box>
                            </Box>
                        </Flex>
                    </ModalBody>
                    {hasButton && <ModalFooter>
                        <Button
                            as={ReachLink}
                            bgGradient="linear(to-r, red.400, orange.400)"
                            _hover={{
                                bgGradient: 'linear(to-r, red.400, orange.400)',
                                boxShadow: 'xl',
                            }}
                            mr={3}
                            to={`/retos/${row.id}`}
                            colorScheme="blue"
                            onClick={onClose}
                        >
                            Ver reto
                        </Button>

                        <Button
                            bgGradient="linear(to-r, blue.500, blue.300)"
                            _hover={{
                                bgGradient: 'linear(to-r, blue.500, blue.300)',
                                boxShadow: 'xl',
                            }}
                            colorScheme="blue"
                            mr={3}
                            onClick={onClose}
                        >
                            Cancelar
                        </Button>
                    </ModalFooter>}
                </ModalContent>
            </Modal>
        </>
    );
}

const Stars = ({ count }) => {
    return (
        <>
            {' '}
            {Array(5)
                .fill('')
                .map((_, i) => (
                    <StarIcon key={i} color={i < count ? 'yellow.500' : 'gray.300'} />
                ))}{' '}
        </>
    );
};
