/**
 * Class that has to be extended in order to create an action.
 */
export default class Action {
  constructor(actionName) {
    this.actionName = actionName;
  }

  /**
   * @description Internal method used to dispatch a thunk in order to support
   * async actions
   * @param {any} payload
   */
  launch(payload) {
    return async (dispatch, getState) => {
      const newState = await this.action(getState(), payload);
      return dispatch({
        type: "MUTATE",
        payload: {},
        newState,
      });
    };
  }

  /**
   * @description Method that has to be overriden. It will act as a reducer.
   * It will return the mutated state.
   * @param {any} state
   * @param {any} payload
   */
  async action(state, payload) {
    return { ...state };
  }
}
